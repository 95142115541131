﻿import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {StorageService} from "../services";

@Injectable()
export class HeadersInterceptor implements HttpInterceptor
{
  constructor(private storage: StorageService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let clonedRequest = request.clone();

    // Check if the request is for the API, if true, set the required headers
    if (request.url.includes(environment.apiUrl) && !request.url.includes('token'))
    {
      if(!clonedRequest.headers.has('Content-Type') || !clonedRequest.headers.has('Authorization'))
      {
        const session = JSON.parse(this.storage.getCurrentSession());

        // Clone the existing headers and add/modify specific headers
        const headers = clonedRequest.headers
          .set('Authorization', `Bearer ${session.access_token}`)
          .set('Content-Type', 'application/json');

        // Clone the request with the updated headers
        clonedRequest = clonedRequest.clone({
          headers: headers
        });
      }
    }

    return next.handle(clonedRequest);
  }
}
