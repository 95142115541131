import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants, IPatientLog, ISearch } from '../models';
import { IResponse } from '../models/i-api-response';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private http: HttpClient,
    private storage: StorageService) { }

    GetPatientsLogs(_search: ISearch): Observable<IResponse<IPatientLog[]>> {
      const TOKEN = JSON.parse(this.storage.getCurrentSession());
      const URL = `api/Patient/GetPatientsLogs`;

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${TOKEN.access_token}`
      });

      return this.http.post<IResponse<IPatientLog[]>>(URL, _search, { headers });
    }

    GetStoredLogsCount(_search: ISearch): Observable<IResponse<number>> {
      const TOKEN = JSON.parse(this.storage.getCurrentSession());
      const URL = `api/Patient/GetStoredLogsCount`;

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${TOKEN.access_token}`
      });

      return this.http.post<IResponse<number>>(URL, _search, { headers });
    }
}
