import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConstants, IPatient } from '../models';
import { IResponse } from '../models/i-api-response';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private http: HttpClient
    , private storage: StorageService
  ) { }

  CreatePatient(_patient: IPatient): Observable<IResponse<IPatient>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `api/Patient/CreatePatient`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<IResponse<IPatient>>(URL, _patient, { headers });
  }

  UpdatePatient(_patient: IPatient): Observable<IResponse<IPatient>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `api/Patient/UpdatePatient`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<IResponse<IPatient>>(URL, _patient, { headers });
  }

  GetPatients(_pattern: string = ''): Observable<IResponse<IPatient[]>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `api/Patient/GetPatients?pattern=${_pattern}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IPatient[]>>(URL, {headers});
  }

  GetPatient(_patientCode: string): Observable<IResponse<IPatient>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const URL = `api/Patient/GetPatient?patientCode=${_patientCode}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<IResponse<IPatient>>(URL, { headers });
  }

}
