import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { ElectronRendererService } from './electronrenderer.service';
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AuthenticationService, PaymentService, StorageService } from './services/index';
import { finalize } from 'rxjs/operators';
import { AppConstants } from './models';
import { StoredTransaction } from './models/i-pp-transaction';
import { IResponse } from './models/i-api-response';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  // varbox
  readonly BACKGROUND_SERVICE_TIME_MINUTES = 2;
  readonly TIME_ELAPSED_BEFORE_SYNC = 5;
  backGroundServiceTime: number = +(JSON.parse(this.storageSerivice.getCurrentSession()) || {}).ServiceExecInterval * 60000 || 60000 * this.BACKGROUND_SERVICE_TIME_MINUTES;
  TimeElapseBeforeSync: number = +(JSON.parse(this.storageSerivice.getCurrentSession()) || {}).SyncTransactionTime * 60000 || 60000 * this.TIME_ELAPSED_BEFORE_SYNC;

  isOnSync = false;
  @BlockUI() blockUI: NgBlockUI;
  title = 'Clavis Consultores SA';
  currentUser: any;
  currencyExchangeRate: number;
  closeWindow = false; //Bandera para cerrar app electron.

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private electronService: ElectronRendererService
    , private storageSerivice: StorageService
    , private paymentService: PaymentService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  /**
   *  Maps a stringed timestamp to a date type
   * @param _timeStamp Date in string format
   * @returns A date type with mapped string values
   */
  GetMappedTimeStamp(_timeStamp: string): Date {
    const SPLITTED_DATE = _timeStamp.split(`%`);

    const YEAR = +SPLITTED_DATE[0];
    const MONTH = +SPLITTED_DATE[1] - 1;
    const DAY = +SPLITTED_DATE[2];
    const HOUR = +SPLITTED_DATE[3];
    const MINUTES = +SPLITTED_DATE[4];
    const SECONDS = +SPLITTED_DATE[5];
    const MILLISECONDS = +SPLITTED_DATE[6];

    return new Date(YEAR, MONTH, DAY, HOUR, MINUTES, SECONDS, MILLISECONDS);
  }

  /**
   * Removes all pinpad transactions
   * @param _storedTransactions All transactions to be removed from local storage
   */
  FlushStoredTransactions = (_storedTransactions: StoredTransaction[]): void => {
    let recordsCount = _storedTransactions.length;
    for (let c = 0; c < recordsCount; c++) this.storageSerivice.FlushPendingTransaction(_storedTransactions[c].StorageKey);
  }

  /**
   * Used to simulate a background thread to sync transactions
   * @param ms Millisecons to be awaited in main flow
   * @returns A promise to be awaited
   */
  Sleep = (ms: number): Promise<unknown> => new Promise(resolve => setTimeout(resolve, ms));

  /**
   * Main flow to sync all uncommited transactions by user
   */
  async TransactionsWorker() {
    // while must be set to true to simulate an infinite thread in the background
    while (true) {
      try {
        const DEFAULT_BACKGROUND_MINUTES = +(JSON.parse(this.storageSerivice.getCurrentSession()) || {}).ServiceExecInterval * 60000;

        const DEFAULT_ELAPSED = +(JSON.parse(this.storageSerivice.getCurrentSession()) || {}).SyncTransactionTime * 60000;

        if (DEFAULT_BACKGROUND_MINUTES > 0 && DEFAULT_BACKGROUND_MINUTES !== this.backGroundServiceTime) {
          this.backGroundServiceTime = +(JSON.parse(this.storageSerivice.getCurrentSession()) || {}).ServiceExecInterval * 60000 || 60000 * this.BACKGROUND_SERVICE_TIME_MINUTES;
        }

        if (DEFAULT_ELAPSED > 0 && DEFAULT_ELAPSED !== this.TimeElapseBeforeSync) {
          this.TimeElapseBeforeSync = +(JSON.parse(this.storageSerivice.getCurrentSession()) || {}).SyncTransactionTime * 60000 || 60000 * this.TIME_ELAPSED_BEFORE_SYNC;
        }
        if (!this.storageSerivice.getCurrentSession()) throw Error(`Not user logged`)

        const pendingTransacions = this.storageSerivice.GetPendingPinPadTransactions();

        if (pendingTransacions && pendingTransacions.length === 0) throw Error(`No transactions to sync`)

        this.ValidateTransactionsStatus(pendingTransacions);
      }
      catch (error) {
        console.info(`${AppConstants.GetError(error)}`);
      }
      await this.Sleep(this.backGroundServiceTime);
    }
  }

  /**
   * Checks if a transactions can be synced
   * @param _storedTransaction A list of pinpad transactions
   */
  ValidateTransactionsStatus(_storedTransaction: StoredTransaction[]): void {

    let transactionsToSync: StoredTransaction[] = [];

    let recordsCount = _storedTransaction.length;

    for (let c = 0; c < recordsCount; c++) {
      const TRANSACTION_DATE: Date = this.GetMappedTimeStamp(_storedTransaction[c].StorageKey.split('x')[2]);
      const CURRENT_DATE = new Date();
      const DIFFERENCE_BETWEEN_DATES = (CURRENT_DATE.getTime() - TRANSACTION_DATE.getTime());

      if (DIFFERENCE_BETWEEN_DATES < this.TimeElapseBeforeSync) continue;

      _storedTransaction[c].SyncUser = JSON.parse(this.storageSerivice.getCurrentSession()).UserName;
      transactionsToSync.push(_storedTransaction[c]);
    }

    if (transactionsToSync.length === 0) throw Error(`Storage without overdue transactions`)

    this.SyncTransaction(transactionsToSync);
  }

  /**
   * 
   * @param _storedTransactions List of transctions to be syncronized in api
   */
  SyncTransaction(_storedTransactions: StoredTransaction[]): void {

    if (this.isOnSync) return;

    this.isOnSync = true;
    this.paymentService.PushTransactions(_storedTransactions).pipe(finalize(() => this.isOnSync = false)).subscribe({
      next: (callback: IResponse<StoredTransaction>[]) => {
        let transactionsToFlush: StoredTransaction[] = [];
        if (callback) {
          callback.forEach((x, i) => {
            if (x.Result) transactionsToFlush.push(_storedTransactions[i])
            else console.info(AppConstants.GetError(callback));
          });

          this.FlushStoredTransactions(_storedTransactions)
        }
      },
      error: error => console.info(AppConstants.GetError(error))
    });
  }

  ngAfterViewInit() {
    // Triggers the transactions backups flow
    this.TransactionsWorker();
  }

  ngOnInit(): void {
    if (this.electronService.CheckElectron()) {
      this.logout();
    }

    if (this.electronService.CheckElectron()) {

      window.onbeforeunload = (e) => {

        if (!this.closeWindow) {
          this.blockUI.stop();
          e.returnValue = false;
          swal({
            type: 'warning',
            title: 'Se cerrará la aplicación',
            text: '¿ Desea continuar ?',
            showCancelButton: true,
            confirmButtonColor: '#049F0C',
            cancelButtonColor: '#ff0000',
            confirmButtonText: 'Cerrar',
            cancelButtonText: 'No'
          }).then(next => {
            if (!(Object.keys(next)[0] === 'dismiss')) {
              this.closeWindow = true;
              this.logoutCloseApp();
              this.electronService.CloseApp();
            }
          });
        }
      };
    }
  }

  /**
   * Flushes all sensitives keys in local storage
   */
  logoutCloseApp() {
    this.authenticationService.logoutCloseApp();
  }

  /**
   * Closes current session and redirect to login
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }
}