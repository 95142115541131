import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {forkJoin, Subscription} from 'rxjs';
import { ITerminal, ITerminalByUser, Users } from 'src/app/models';
import { AlertService, AuthenticationService, BankService, PermsService, UserService } from 'src/app/services';
import {TerminalsService} from "../../../services/terminals.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss']
})
export class TerminalsComponent implements OnInit {
  //VARBOX
  inputType: string;
  isOnWriteMode: boolean;
  terminalsByUser: ITerminalByUser[];
  users: Users[];
  userControl: FormControl;
  currentJustify: string;
  terminals: ITerminal[]; // Lista de los terminales obtenidos
  @BlockUI() blockUI: NgBlockUI; // Para bloquear la interfaz mientras se realiza una acccion
  @ViewChild("UserName") UserName: ElementRef; // Para mandar el focus a este elemento
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true; // Para comprobar los permisos del usuario
  terminalModalTitle: string; // Titulo dinamico para la modal
  terminalTarget: ITerminal; // Modelo usando para actualizar una terminal
  isValidForm: boolean; // Representa el estado  del formulario aprobado-rechazado
  terminalForm = new FormGroup({
    Id: new FormControl(''),
    TerminalId: new FormControl('', [Validators.required]),
    Description: new FormControl('', [Validators.required]),
    Status: new FormControl(''),
    Currency: new FormControl('', [Validators.required])
  }); // La definicio del formulario del terminal

  constructor(
    private sPerm: PermsService
    , private bankService: BankService
    , private alertService: AlertService
    , private userService: UserService,
    private terminalsService: TerminalsService,
    private authenticationService: AuthenticationService) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
  }

  ngOnInit() {
    this.initVariables();
    this.SetInitialRequests();
  }

  /**
   * Send all initial required requests for the component
   * @constructor
   */
  SetInitialRequests(): void
  {
    this.blockUI.start("Obteniendo información requerida...");

    forkJoin([
      this.sPerm.getPerms(this.currentUser.userId),
      this.terminalsService.GetTerminals(false),
      this.userService.getUsersApp()
    ])
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe({
        next: (callbacks) => {
          // Permissions logic
          if(callbacks[0].Result)
          {
            this.permisos = callbacks[0].perms.some(p => p.Name === "V_Terminal");
          }
          else
          {
            this.permisos = false;
          }

          // Terminals logic
          if (callbacks[1].Result)
          {
            this.alertService.successInfoAlert(`Terminales obtenidos`);
            this.terminals = callbacks[1].Data;
          }
          else
          {
            if (callbacks[1].Data.length === 0)
            {
              this.alertService.infoInfoAlert(`No hay terminales registrados en el sistema`);
            }
            else
            {
              this.alertService.infoAlert(`Error el obtener los terminales: ${callbacks[1].Error.Message}`);
            }
          }

          // Users logic
          if (callbacks[2].Result)
          {
            this.users = callbacks[2].Users;
          }
        },
        error: (err) => {
          this.alertService.errorAlert(`${err}`);
        }
      });
  }

  // Trigger para levantar la modal de creacion de terminal
  raiseTerminalModalCreation(): void {
    this.ResetTerminalForm();
    this.isValidForm = true;
    this.terminalModalTitle = 'Crear terminal';
    (<HTMLButtonElement>document.getElementById('triggerRaiseTerminalModal')).click();
    this.terminalForm.patchValue({ Status: true });
    this.terminalTarget = {} as ITerminal;
  }
  /**
   * Execute a request of the specified terminals a then show a modal with the terminal info
   * @param _terminalId The terminal id to request
   * @constructor
   */
  RaiseTerminalModal(_terminalId: number): void {
    this.blockUI.start("Obteniendo datos de terminal...");

    this.terminalsService.GetTerminal(_terminalId)
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe({
        next: (callback) => {
          this.terminalTarget = {} as ITerminal;

          if (callback.Result)
          {
            this.ResetTerminalForm();

            this.terminalModalTitle = 'Editar terminal';

            this.terminalTarget = callback.PPTerminal;

            this.terminalForm.patchValue({ ...callback.PPTerminal });

            (<HTMLButtonElement>document.getElementById('triggerRaiseTerminalModal')).click();
          }
          else
          {
            this.alertService.errorAlert(`Error al obtener la terminal, detalle: ${callback.Error.Message}`);
          }
        },
        error: (err) => {
          this.alertService.errorAlert(`Error al obtener la terminal, detalle: ${err}`);
        }
      });
  }
  /**
   * Execute a request to update or create a terminal depending if a terminals is being updated
   * @constructor
   */
  SaveTerminal(): void {
    this.terminalTarget = { ...this.terminalForm.value } as ITerminal;

    this.isValidForm = this.terminalForm.valid;

    this.userControl.setValue("-1");

    if (this.isValidForm)
    {
      (<HTMLButtonElement>document.getElementById('hideButtonTrigger')).click();

      this.blockUI.start('Procesando transacción, espere por favor');

      if (this.terminalTarget.Id)
      {
        this.terminalsService.UpdateTerminal(this.terminalTarget)
          .pipe(finalize(() => this.blockUI.stop()))
          .subscribe({
            next: (callback) => {
              if (callback.Result)
              {
                this.alertService.successInfoAlert(`Terminal actualizado con éxito`);
                this.GetTerminals();
              }
              else
              {
                this.alertService.errorAlert(`Error al actualizar la terminal, detalle: ${callback.Error.Message}`);
              }
            },
            error: (err) => {
              this.alertService.errorAlert(`Error al actualizar la terminal, detalle: ${err}`);
            }
          });
      }
      else
      {
        this.terminalsService.CreateTerminal(this.terminalTarget)
          .pipe(finalize(() => this.blockUI.stop()))
          .subscribe({
            next: (callback) => {
              if (callback.Result)
              {
                this.alertService.successInfoAlert(`Terminal creado con éxito`);
                this.GetTerminals();
              }
              else
              {
                this.alertService.errorAlert(`Error al crear la terminal, detalle: ${callback.Error.Message}`);
              }
            },
            error: (err) => {
              this.alertService.errorAlert(`Error al crear la terminal, detalle: ${err}`);
            }
          });
      }
    }
  }

  // Inicializa las variables a un estado por defecto
  initVariables(): void {
    this.inputType = `text`;
    this.isOnWriteMode = false;
    this.terminals = [];
    this.terminalsByUser = [];
    // this.CheckPermits();
    this.ResetTerminalForm();
    this.ResetUserControl();
  }

  ResetUserControl(): void {
    this.userControl = new FormControl(-1);
  }
  // Restablece a un estado por defect el formulario del terminal
  ResetTerminalForm(): void {
    this.terminalForm = new FormGroup({
      Id: new FormControl(''),
      TerminalId: new FormControl('', [Validators.required]),
      Description: new FormControl('', [Validators.required]),
      Status: new FormControl(''),
      Currency: new FormControl('COL'),
      QuickPayAmount: new FormControl(0, [Validators.required])
    });

    this.currentJustify = 'start';
  }

  /**
   * Execute a request to get the terminals
   * @constructor
   */
  GetTerminals(): void {
    this.blockUI.start("Obteniendo terminales...");

    this.terminalsService.GetTerminals(false)
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe({
        next: (callback) => {
          if (callback.Result)
          {
            this.alertService.successInfoAlert(`Terminales obtenidos`);
            this.terminals = callback.Data;
          }
          else
          {
            if (callback.Data.length === 0)
            {
              this.alertService.infoInfoAlert(`No hay terminales registrados en el sistema`);
            }
            else
            {
              this.alertService.infoAlert(`Error el obtener los terminales: ${callback.Error.Message}`);
            }
          }
        },
        error: (error) => {
          this.alertService.errorAlert(`Error al obtener la terminal, detalle: ${error}`);
        }
      });
  }
  // Alterna el estado del terminal entre activo inactivo
  toggleStatus(): void {
    this.terminalForm.patchValue({
      Status: !this.terminalForm.value.Status
    });
  }

  /**
   * Execute a request to get the selected user assigned terminals
   * @param event
   * @constructor
   */
  OnUserControlChange(event): void {
    this.blockUI.start(`Obteniendo terminales asignadas al usuario...`);

    this.terminalsByUser = [];

    this.terminals.forEach(x => x.IsDefault = false);

    this.terminalsService.GetTerminalsByUser(this.userControl.value)
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe({
        next: (callback) => {
          if (callback.Result)
          {
            this.terminalsByUser = callback.Data;

            this.terminals.forEach(x => {
              this.terminalsByUser.forEach(y => {
                if (x.TerminalId === y.TerminalId)
                {
                  x.IsDefault = y.IsDefault;
                }
              });
            });
          }
          else
          {
            this.alertService.errorInfoAlert(`Error: ${callback.Error.Message || ''}`);
          }
        },
        error: (err) => {
          this.alertService.errorAlert(`Error: ${err}`);
        }
      });
  }

  AssignTerminal(_terminal: ITerminal, _isAssinged: boolean): void {
    if (this.userControl.value == -1) {
      this.alertService.infoInfoAlert(`Seleccione un usuario`);
      return;
    }
    if (_isAssinged) {
      this.terminalsByUser = this.terminalsByUser.filter(x => x.TerminalId !== _terminal.TerminalId);
    }
    else {
      this.terminalsByUser.push({
        TerminalId: _terminal.TerminalId,
        UserId: this.userControl.value,
        Id: -1,
        IsDefault: _terminal.IsDefault
      });
    }
    console.log(this.terminalsByUser);
  }

  // terminal.Status
  IsAssigned(_terminalId: string): boolean {
    return this.terminalsByUser.some(x => {
      return x.TerminalId == _terminalId;
    });
  }

  /**
   * Execute a request to update the user assigned terminals
   * @constructor
   */
  UpdateTerminalsByUser(): void {
    if (this.userControl.value == -1)
    {
      this.alertService.infoInfoAlert(`Seleccione un usuario`);
      return;
    }

    this.blockUI.start(`Procesando, espere por favor`);

    this.terminalsByUser.forEach(x => {
      this.terminals.forEach(y => {
        if (y.TerminalId === x.TerminalId) x.IsDefault = y.IsDefault;
      });
    })

    this.terminalsService.UpdateTerminalsByUser(this.terminalsByUser, this.userControl.value)
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe({
        next: (callback) => {
          if (callback.Result)
          {
            this.alertService.successInfoAlert(`Asignaciones actualizadas`);
          }
          else
          {
            this.alertService.errorInfoAlert(`Error: ${callback.Error.Message || ''}`);
          }
        },
        error: (err) => {
          this.alertService.errorAlert(`Error: ${err}`);
        }
      });
  }

  FilteredTerminals(): ITerminal[] {
    return this.terminals.filter(x => x.Status);
  }

  ToggleAmountEdition(_isOnWriteMode, _event = undefined): void {
    if (_isOnWriteMode) {
      this.inputType = `number`;
    }
    else {
      this.inputType = `text`;
    }

    this.isOnWriteMode = _isOnWriteMode;
  }

  SetTerminalDefault(_terminal: ITerminal, _checked: any): void {
    this.terminals.filter(x => x.Currency === _terminal.Currency).forEach(x => {
      if (x.TerminalId === _terminal.TerminalId) {
        x.IsDefault = _checked.target.checked;
      } else {
        x.IsDefault = false;
      }
    });
  }
}
