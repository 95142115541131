﻿export const environment = {
  production: false,
  reportManagerUrl: 'https://rptmanagertest.clavisco.com/',
  apiUrl: navigator.onLine ? 'https://emacncsaludapitest.clavisco.com/' : 'http://localhost:42222/',
  offlineApiUrl: 'http://localhost:42222/',
  name: 'CNC Salud',
  envType: 'Testing',
  description: 'Testing environment - CNC Salud',
  appKey: 'AppSuper'
};
