import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { AppConstants, IPatient, IModalStatus, CONFIG_VIEW, IdentificationType } from 'src/app/models';
import { IBPCardCodes } from 'src/app/models/i-business-partner';
import { IIdentificationType } from 'src/app/models/i-identification-type';
import { AlertService, BusinessPartnerService, CompanyService, PatientService } from 'src/app/services';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class PatientComponent implements OnInit {
  //varbox
  searching: boolean;
  DEFAULT_BUSINESS_PARTNER: string; //Creacion carga cliente configurado en consola, edicion de documento
  @BlockUI() blockUI: NgBlockUI;
  @Input() modalStatus: IModalStatus<IPatient>;
  modalText: string;
  patientForm: FormGroup;
  cardCodeList: IBPCardCodes[];
  focusCCode$: Subject<string>;
  defaultPatientCardCode: string;
  IdentificationTypes$: Observable<IIdentificationType[]>;
  Patients: IPatient[];
  selectedIdentificationType: IIdentificationType;
  constructor(
    private alertService: AlertService
    , private modalService: NgbModal
    , private formBuilder: FormBuilder
    , private patientService: PatientService
    , private bpService: BusinessPartnerService
    , private companyService: CompanyService
  ) { }

  ngOnInit() {
    this.searching = false;
    this.cardCodeList = [];
    this.defaultPatientCardCode = "";
    this.GetPatients();
    this.GetIdentificationTypes();
    this.SetUp();
    this.GetDefaultBussinesPartnerSettings();
    this.focusCCode$ = new Subject<string>();
    if (!this.modalStatus.IsCreating) {
      this.patientForm.setValue({
        U_CardCode: this.modalStatus.Data.U_CardCode,
        U_Identificacion: this.modalStatus.Data.U_Identificacion,
        U_TipoIdentificacion: this.modalStatus.Data.U_TipoIdentificacion,
        U_Nombre: this.modalStatus.Data.U_Nombre,
        U_Apellido1: this.modalStatus.Data.U_Apellido1,
        U_Apellido2: this.modalStatus.Data.U_Apellido2,
        U_FechaNacimiento: formatDate(this.modalStatus.Data.U_FechaNacimiento, "yyyy-MM-dd", "en"),
        U_Telefono1: this.modalStatus.Data.U_Telefono1,
        U_Telefono2: this.modalStatus.Data.U_Telefono2,
        U_Celular1: this.modalStatus.Data.U_Celular1,
        U_Celudar2: this.modalStatus.Data.U_Celular2,
        U_Email: this.modalStatus.Data.U_Email,
        U_Direccion: this.modalStatus.Data.U_Direccion,
        U_Observacion: this.modalStatus.Data.U_Observacion,
        U_Est_Paciente: this.modalStatus.Data.U_Est_Paciente == "01"
      });
    }
    else
    {
      this.patientForm.controls["U_Identificacion"].disable();
    }
  }

  GetIdentificationTypes() {
    this.blockUI.start("Obteniendo tipos de identificación...");

    this.bpService.GetIdentificationTypes().pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (callback) => {
        this.IdentificationTypes$ = of(callback.Data);
      },
      error: (error) => {
        console.error(error);

        this.alertService.errorAlert(error);
      }
    });
    }

  SetUp(): void {
    this.patientForm = this.formBuilder.group({
      U_CardCode: '',
      U_Identificacion: ['', Validators.required],
      U_TipoIdentificacion: ['00', Validators.required],
      U_Nombre: ['', Validators.required],
      U_Apellido1: ['', Validators.required],
      U_Apellido2: '',
      U_FechaNacimiento: '',
      U_Telefono1: ['', Validators.required],
      U_Telefono2: '',
      U_Celular1: '',
      U_Celudar2: '',
      U_Email: ['', Validators.compose([Validators.required, Validators.email])],
      U_Direccion: '',
      U_Observacion: '',
      U_Est_Paciente: true
    });
  }

  DismisComponent(): void {
    this.modalService.dismissAll({} as IPatient);
  }

  CreatePatient(): void {

    if(!this.CheckIfSelectedCustomerExist()) return;

    if(this.patientForm.invalid)
    {
      this.alertService.infoAlert("Falta campos por completar o contienen valores invalidos");

      for(const control in this.patientForm.controls)
      {
        if(this.patientForm.controls[control].untouched)
        {
          this.patientForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    if(this.CheckIfIdentificationExist())
    {
      this.alertService.errorAlert("No se puede usar este número de identificación debido a que ya fue utilizado");
      return;
    }

    let patient = this.patientForm.value as IPatient;

    patient.U_Est_Paciente = this.patientForm.controls.U_Est_Paciente.value ? "01" : "02";

    if (this.cardCodeList.find(cc => `${cc.CardCode} ${cc.CardName}` === patient.U_CardCode)) {
      patient.U_CardCode = this.cardCodeList.find(cc => `${cc.CardCode} ${cc.CardName}` === patient.U_CardCode).CardCode;
    }

    this.blockUI.start(`Procesando, espere por favor`);

    this.patientService.CreatePatient(patient).pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (next) => {
        if (next.Result) {
          if (next.Error) {
            this.alertService.Continue(AppConstants.GetError(next.Error), `info`);
          }
          else {
            const MODAL_STATUS = {
              Data: next.Data,
              IsCompleted: true,
              HeaderText: null,
              IsCreating: false
            } as IModalStatus<IPatient>;

            this.alertService.Continue(`Paciente creado`, `success`).then(result => {
              this.modalService.dismissAll(MODAL_STATUS);
            });
          }
        }
        else {
          this.alertService.errorAlert(AppConstants.GetError(next.Error));
        }
      },
      error: error => {
        this.alertService.Continue(AppConstants.GetError(error), `error`);
      }
    });
  }

  UpdatePatient(): void {
    if(!this.CheckIfSelectedCustomerExist()) return;

    if(this.patientForm.invalid)
    {
      this.alertService.infoAlert("Falta campos por completar o contienen valores invalidos");

      for(const control in this.patientForm.controls)
      {
        if(this.patientForm.controls[control].untouched)
        {
          this.patientForm.controls[control].markAsTouched();
        }
      }

      return;
    }

    if(this.CheckIfIdentificationExist())
    {
      this.alertService.errorAlert("No se puede usar este número de identificación debido a que ya fue utilizado");
      return;
    }
    const PATIENT = { ...this.patientForm.value, Code: this.modalStatus.Data.Code } as IPatient;
    PATIENT.U_Est_Paciente = this.patientForm.controls.U_Est_Paciente.value ? "01" : "02";

    if (this.cardCodeList.find(cc => `${cc.CardCode} ${cc.CardName}` === PATIENT.U_CardCode)) {

      PATIENT.U_CardCode = this.cardCodeList.find(cc => `${cc.CardCode} ${cc.CardName}` === PATIENT.U_CardCode).CardCode;
    }

    this.blockUI.start(`Procesando, espere por favor`);

    this.patientService.UpdatePatient(PATIENT).pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (next) => {
        if (next.Result) {
          if (next.Error) {
            this.alertService.Continue(AppConstants.GetError(next.Error), `info`);
          }
          else {
            const MODAL_STATUS = {
              Data: PATIENT,
              IsCompleted: true,
              HeaderText: null,
              IsCreating: false
            } as IModalStatus<IPatient>;

            this.alertService.Continue(`Paciente actualizado`, `success`).then(result => {
              this.modalService.dismissAll(MODAL_STATUS);
            });
          }
        }
        else {
          this.alertService.errorAlert(AppConstants.GetError(next.Error));
        }
      },
      error: error => {
        this.alertService.Continue(AppConstants.GetError(error), `error`);
      }
    });
  }

  GetCardCodes(): void {
    this.blockUI.start();
    this.bpService.GetCustomerCardCodes().pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (next) => {
        if (next.Result) {
          if (next.Error) {
            this.alertService.Continue(AppConstants.GetError(next.Error), `info`);
          }
          else {
            this.cardCodeList = [...next.Data];

            if (this.modalStatus.IsCreating) {
              let cardCode: IBPCardCodes = this.cardCodeList.find(cc => cc.CardCode === this.DEFAULT_BUSINESS_PARTNER);

              if (!cardCode) {
                let cardName: IBPCardCodes = this.cardCodeList.find(cc => cc.CardCode === this.DEFAULT_BUSINESS_PARTNER);
                cardCode = {
                  CardCode: this.DEFAULT_BUSINESS_PARTNER,
                  CardName: cardName.CardName
                }
              }

              this.patientForm.controls.U_CardCode.setValue(`${cardCode.CardCode} ${cardCode.CardName}`);

            }
            else {
              let cardName = this.cardCodeList.find(x => x.CardCode === this.modalStatus.Data.U_CardCode) ? this.cardCodeList.find(x => x.CardCode === this.modalStatus.Data.U_CardCode).CardName : ``;

              if (!this.modalStatus.Data.U_CardCode || this.modalStatus.Data.U_CardCode === "") {
                let cardName: IBPCardCodes = this.cardCodeList.find(cc => cc.CardCode === this.DEFAULT_BUSINESS_PARTNER);
                let cardCode = {
                  CardCode: this.DEFAULT_BUSINESS_PARTNER,
                  CardName: cardName.CardName
                }
                this.patientForm.controls.U_CardCode.setValue(`${cardCode.CardCode} ${cardCode.CardName}`);

                this.alertService.infoInfoAlert(`El paciente no tenía código asociado, se ha precargado con el código de socio por defecto`);
              }
              else {
                this.patientForm.controls.U_CardCode.setValue(`${this.modalStatus.Data.U_CardCode} ${cardName}`);
              }

            }
          }
        }
        else {
          this.alertService.errorAlert(AppConstants.GetError(next.Error));
        }
      },
      error: (error) => {
        this.alertService.Continue(AppConstants.GetError(error), `error`);
      }
    });
  }

  log(a: any) {
    console.log(a)
  }

  searchCardCode = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );

    const inputFocus$ = this.focusCCode$;

    return merge(debouncedText$, inputFocus$).pipe(
      map((term) =>
        (term === ""
          ? this.cardCodeList.map(cc => `${cc.CardCode} ${cc.CardName}`)
          : this.cardCodeList.map(cc => `${cc.CardCode} ${cc.CardName}`).filter(
            (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )
    );
  }

  GetPadron(): void {
    this.blockUI.start(`Procesando, espere por favor`);
    this.bpService.GetCustomersContPadron(this.patientForm.controls.U_Identificacion.value).pipe(finalize(() => {this.blockUI.stop();this.searching = false;})).subscribe((data) => {
      if (data.tipoIdentificacion == this.patientForm.controls.U_TipoIdentificacion.value) {
        let namePart: string[] = data.nombre.split(" ");
        let nombres: string = "";
        let apellido1: string = "";
        let apellido2: string = "";
        if(data.tipoIdentificacion === '02')
        {
          nombres = data.nombre;
        }
        else
        {
          switch (namePart.length) {
            case 4:
              nombres = `${namePart[0]} ${namePart[1]}`;
              apellido1 = namePart[2];
              apellido2 = namePart[3];
              break;
            case 3:
              nombres = namePart[0];
              apellido1 = namePart[1];
              apellido2 = namePart[2];
              break;
            default:
              this.alertService.infoInfoAlert(`No se pudo mapear la cédula solicitada, se ha cargado todo el resultado en el nombre`);
              nombres = data.nombre;
              break;
          }
        }

        if (this.IdentificationHasChanged()) {
          this.alertService.infoInfoAlert(`Se han obtenido los nuevos datos de paciente con identificación ${this.patientForm.controls.U_Identificacion.value}`);
        }

        this.patientForm.patchValue({ U_Nombre: nombres, U_Apellido1: apellido1, U_Apellido2: apellido2 });
      }
      else {
        this.alertService.errorInfoAlert(`Error el campo tipo de cedula no coincide con el tipo de la cedula ingresada`);
      }
    }, (error: any) => {
      this.alertService.errorInfoAlert(`Ocurrió un error al consultar el padrón de hacienda. Detalle: ${error}`);
    });
  }

  IdentificationHasChanged(): boolean {
    return !this.modalStatus.IsCreating && this.patientForm.controls.U_Identificacion.value !== this.modalStatus.Data.U_Identificacion &&
      `${this.patientForm.controls.U_Nombre.value} ${this.patientForm.controls.U_Apellido1.value} ${this.patientForm.controls.U_Apellido2.value}` == `${this.modalStatus.Data.U_Nombre} ${this.modalStatus.Data.U_Apellido1} ${this.modalStatus.Data.U_Apellido2}`;
  }

  GetDefaultBussinesPartnerSettings(): void {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.BussinesPartner).subscribe(response => {
      if (response.Result) {
        let result = JSON.parse(response.Data.Json);
        this.DEFAULT_BUSINESS_PARTNER = result.DefaultCustomerOnPatientModal;

        this.GetCardCodes();

      } else {
        this.alertService.errorAlert('Ocurrió un error obteniendo configuración de socios de negocios por defecto ' + response.Error.Message);
        this.GetCardCodes();
      }
    }, err => {
      this.alertService.errorAlert('Ocurrió un error obteniendo configuración de socios de negocios por defecto ' + err);
      this.GetCardCodes();
    });
  }

  GetPatients(): void{
    this.patientService.GetPatients().subscribe({
      next: (callback) =>  {
        this.Patients = callback.Data;
      },
      error: (error) =>
      {
        console.error(error);
      }
    });
  }

  CheckIfIdentificationExist(): boolean{
    let patientCode: number = 0;

    if(!this.modalStatus.IsCreating) patientCode = this.modalStatus.Data.Code;

    return this.Patients.some(p => p.Code !== patientCode && p.U_Identificacion === this.patientForm.controls["U_Identificacion"].value);
  }

  OnIdentificationTypeChange(): void{
    this.IdentificationTypes$.subscribe(
      list => {
        this.selectedIdentificationType = list.find(c => c.Code === this.patientForm.controls.U_TipoIdentificacion.value);

        this.patientForm.controls['U_Identificacion'].setValidators(
          [
            Validators.required,
            Validators.min(this.selectedIdentificationType.GreaterThan),
            Validators.minLength(this.selectedIdentificationType.MinCharacters),
            Validators.maxLength(this.selectedIdentificationType.MaxCharacters)
          ]);

        this.patientForm.controls['U_Identificacion'].updateValueAndValidity();
        this.patientForm.controls['U_Identificacion'].markAsUntouched();

        if(this.selectedIdentificationType.Code === '02'){
          this.patientForm.controls['U_Apellido1'].clearValidators();
        } else {
          this.patientForm.controls['U_Apellido1'].setValidators(Validators.required);
        }

        this.patientForm.controls['U_Apellido1'].updateValueAndValidity();
        this.patientForm.controls['U_Apellido1'].markAsUntouched();

        if(this.selectedIdentificationType.Code === '00'){
          this.patientForm.controls.U_Identificacion.disable();
        } else {
          this.patientForm.controls.U_Identificacion.enable();
        }
      });
  }

  IdentificationIsCorrect(): void{
    if(!this.searching)
    {
      this.searching = true;

      let isCorrect: boolean = true;

      let value: string = this.patientForm.controls.U_Identificacion.value;

      if(value.length < this.selectedIdentificationType.MinCharacters) isCorrect = false;

      if(this.selectedIdentificationType.GreaterThan > -1 && Number(value) < this.selectedIdentificationType.GreaterThan) isCorrect = false;

      if(this.selectedIdentificationType.MaxCharacters > -1 && value.length > this.selectedIdentificationType.MaxCharacters) isCorrect = false;

      if(!isCorrect)
      {
        this.alertService.errorAlert("El número de cédula no cumple con el formato requerido")

        this.searching = false;

        return;
      }

      this.GetPadron();
    }
  }

  CheckIfSelectedCustomerExist(): boolean
  {
    let cardCode: string = this.patientForm.get("U_CardCode").value;

    let exist = this.cardCodeList.some(cc =>  cardCode.includes(cc.CardCode));

    if(!exist)
    {
      this.alertService.errorInfoAlert("El código del cliente ingresado no existe, por favor ingréselo de nuevo");
      this.patientForm.get("U_CardCode").setValue('');
    }

    return exist;
  }
}
