import { Component, OnInit, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { saveAs } from 'file-saver';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';

const printJS = require('print-js');

// MODELOS

import { PaydeskBalance, CONFIG_VIEW, ITerminalByUser, ITerminal, ICommitedTransaction } from 'src/app/models/index';
// RUTAS

// COMPONENTES

// SERVICIOS
import { PermsService, DocumentService, ReportsService, ParamsService, AlertService, SalesManService, StorageService, DailybalanceService, CommonService, CompanyService, BankService, AuthenticationService } from '../../../services/index';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { element } from '@angular/core/src/render3';

// Electron renderer service
import { ElectronRendererService } from '../../../electronrenderer.service';
import { NgbModal, ModalDismissReasons, NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import {finalize} from "rxjs/operators";

// PIPES

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss'],
  providers: [DatePipe]
})
export class BalanceComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('tabset') tabSet: NgbTabset;

  paydeskBalanceForm: FormGroup;
  reprintInvoiceForm: FormGroup; // nombre del formulario de facturas a reimprimir
  correoForm: FormGroup; // Formulario de correo
  // whatsappForm: FormGroup; // Formulario de whatsapp

  Date: Date; // fecha actual
  NowDate1: any;
  Zoom: number;
  IsLocked: boolean;
  CloseResult: string;
  ModalPay: any;

  AddCc: boolean = true;//Agregar copia correo
  AddCopiaMail: boolean = false;
  BtnOptionDocument: boolean = false; //Opcion del Documento
  Base64Report: string;

  Pinpad = false;
  currentUserTerminals: ITerminal[];
  Total: string;
  permisos = true;
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  disableRequestPinPadTotalButton: boolean = true;
  constructor(private fbs: FormBuilder,
    private sPerm: PermsService,
    private invSer: DocumentService,
    private alertService: AlertService,
    private salesManService: SalesManService,
    private repoSer: ReportsService,
    private electronRendererService: ElectronRendererService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private storageService: StorageService,
    private datePipe: DatePipe,
    private paydeskService: DailybalanceService,
    private commonService: CommonService,
    private companyService: CompanyService,
    private banksService: BankService,
    private permService: PermsService,
    private authenticationService: AuthenticationService
  ) {
    this.electronRendererService.on('Print', (event: Electron.IpcMessageEvent, ...arg) => {
      console.log("response from electron render service: load print listener" + arg);
    });
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

  }


  ngOnInit() {
    this.Total = '';
    this.IsLocked = true;
    this.Zoom = 0.5;
    this.BtnOptionDocument = false;
    this.Date = new Date();
    this.NowDate1 = `${this.Date.getFullYear()}-${('0' + (this.Date.getMonth() + 1)).slice(-2)}-${('0' + this.Date.getDate()).slice(-2)}T${(this.Date.getHours())}:${('0' + this.Date.getMinutes()).slice(-2)}:${('0' + this.Date.getSeconds()).slice(-2)}`;

    this.reprintInvoiceForm = this.fbs.group({
      UserName: [{ value: '', disabled: true }, Validators.required],
      UserCode: [{ value: '', disabled: true }, Validators.required],
      FechaIni: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      FechaFin: [''],
    });
    this.BtnOptionDocument = false;


    this.correoForm = this.fb.group({
      emailsend: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      subject: ['', Validators.required],
      message: [''],
      tags: [''],//this.fb.array([])
    });
    this.AddCopiaMail = false;

    this.paydeskBalanceForm = this.fb.group({
      Cash: [0, Validators.required],
      Cards: [0, Validators.required],
      Transfer: [0, Validators.required],
      CardsPinpad: [0, Validators.required],
      Terminal: ['']
    });

    this.CheckPermits();
    this.setLoggedUserName();
    this.GetSettings();
    this.currentUserTerminals = this.storageService.GetPPTerminals();
  }
  get formControls() {
    return this.paydeskBalanceForm.controls;
  }
  async onClickCreatePaydeskBalance() {
    if (!this.Pinpad) {
      this.paydeskBalanceForm.get('CardsPinpad').setValue(0);
    }
    if (this.paydeskBalanceForm.invalid) {
      const CONFIRMATIONRESULT = await this.alertService.ConfirmationAlert('Confirmación', 'No has completado valores para el cierre de caja. ¿Continuar de todas formas?', 'Continuar');
      if (!CONFIRMATIONRESULT) return;
    }

    let paydeskBalance = this.paydeskBalanceForm.value as PaydeskBalance;

    this.blockUI.start('Procesando...');

    this.paydeskService.PostPaydeskBalance(paydeskBalance)
      .subscribe(response => {
        this.blockUI.stop();

        if (response.Result) {
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
          this.paydeskBalanceForm.reset();
          this.tabSet.select('tabSearch');
          this.BtnOptionDocument = true;
          this.Base64Report = response.File;
          this.pdfSrc = this.base64ToArrayBuffer(response.File) as ArrayBuffer;
        } else {
          this.alertService.errorAlert(response.Error.Message);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }
  close() {
    this.ModalPay.close();
    this.AddCc = true;
  }

  get ced() {
    return this.correoForm.controls;
  }
  deleteCc(index: number): void {
    // this.tags.removeAt(index);
    //this.tags.markAsDirty();
    this.AddCc = true;
    this.AddCopiaMail = false;
    this.correoForm.patchValue({ tags: "" });
  }
  get tags(): FormArray {
    return this.correoForm.get('tags') as FormArray;
  }
  addCorreo(): void {
    this.AddCopiaMail = true;
    //this.tags.push(new FormControl());
    this.AddCc = false;
  }

  SendDocument() {
    this.blockUI.start('Enviando documento...'); // Start blocking
    const CORREOMODEL = {
      emailsend: this.correoForm.value.emailsend,
      subject: this.correoForm.value.subject,
      message: this.correoForm.value.message,
      EmailCC: this.correoForm.value.tags
    };
    const BALANCEMODEL = {
      User: this.reprintInvoiceForm.value.UserCode,
      FIni: this.reprintInvoiceForm.value.FechaIni,
      FFin: this.reprintInvoiceForm.value.FechaFin,
      DBCode: null,
      sendMailModel: CORREOMODEL
    };
    this.repoSer.sendDocument(BALANCEMODEL)
      .subscribe(data => {
        this.blockUI.stop();
        if (data.Result) {
          this.alertService.successAlertHtml(`Documento enviado por correo correctamente`);
          this.close();
          this.AddCopiaMail = false;
        } else {
          this.alertService.errorAlert(`Error al enviar documento al correo: ${data.Error.Code}-${data.Error.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  //Funcion abrir modal enviar por correo
  sendOptionDocumento(content) {
    this.correoForm = this.fb.group({
      emailsend: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      subject: ['', Validators.required],
      message: [''],
      tags: [''],//this.fb.array([])
    });
    this.ModalPay = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' });
    this.ModalPay.result.then((result) => {

      this.CloseResult = `Closed with: ${result}`;
    }, (reason) => {
      this.CloseResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.correoForm = this.fb.group({
        emailsend: [''],
        subject: ['', Validators.required],
        message: [''],
        tags: [''],//this.fb.array([])
      });
    });
  }

  pdfSrc: ArrayBuffer;// = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
  // se encarga de imprimir
  printReport() {

    if (!this.Base64Report) return;
    if (this.electronRendererService.CheckElectron()) {
      var date = new Date();
      let fileName = 'BalanceReporrt_' + date.getTime() + '.pdf';
      let file = { "fileName": fileName, "file": this.Base64Report };
      this.electronRendererService.send('Print', file);
    }
    else {
      printJS({
        printable: this.Base64Report,
        type: 'pdf',
        base64: true
      })
    }
  }
  base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  onTabChange($event: any) {
    if ($event.nextId === 'tabSearch') {
      this.paydeskBalanceForm.reset();
    } else {
      this.pdfSrc = null;
      this.Base64Report = null;
      this.BtnOptionDocument = false;
    }
  }
  setLoggedUserName() {
    let session: any = this.storageService.getCurrentSession();
    if (!session) return;

    session = JSON.parse(session);
    this.reprintInvoiceForm.get('UserName').setValue(session.UserName);
  }

  onClickSearchPaydeskBalance() {

    if (!this.reprintInvoiceForm.get('UserName').value || !this.reprintInvoiceForm.get('FechaIni').value) {
      this.alertService.infoInfoAlert('Campos requeridos con formato inválido');
      return;
    }

    this.blockUI.start('Procesando...');
    this.paydeskService.GetPaydeskBalance(this.reprintInvoiceForm.get('FechaIni').value)
      .subscribe(response => {
        this.blockUI.stop();
        if (response.Result) {
          this.BtnOptionDocument = true;
          this.IsLocked = false;
          this.Base64Report = response.File;

          this.pdfSrc = this.base64ToArrayBuffer(response.File) as ArrayBuffer;


        } else {
          this.alertService.errorAlert(response.Error.Message);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
  }

  onClickDownloadPdf() {
    if (!this.Base64Report) return;

    this.commonService.downloadFile(this.Base64Report, `Cierre de caja ${new Date().toISOString().split('T')[0]}`, 'application/pdf', 'pdf');
  }
  GetSettings(): void {
    this.companyService.GetSettingsbyId(CONFIG_VIEW.Payment).subscribe(response => {
      if (response.Result) {
        let result = JSON.parse(response.Data.Json);
        this.Pinpad = result.Pinpad
      } else {
        this.alertService.errorAlert('Ocurrió un error obteniendo configuracion compañía uso de pinpad ' + response.Error.Message);
      }
    }, err => {
      this.alertService.errorAlert('Ocurrió un error obteniendo configuracion compañía uso de pinpad ' + err);

    });
  }
  onClickSearchPinpad(): void {

    let terminalId = this.paydeskBalanceForm.get('Terminal').value;

    if(!terminalId)
    {
      this.alertService.infoInfoAlert("Debe seleccionar una terminal");
      return;
    }

    this.blockUI.start(`Obteniendo totales transacciones de pinpad...`);

    this.banksService.GetTransactionsPinpadTotal(terminalId)
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe(next => {
      if (next.Result)
      {
        this.Total = next.Data;

        if (this.Total !== '0')
        {
          this.Total = next.Data;
        }

        this.paydeskBalanceForm.patchValue({ CardsPinpad: this.Total });

        this.alertService.infoInfoAlert(`Tarjetas con transacciones pinpad obtenidas exitosamente`);
      }
      else
      {
        this.alertService.errorAlert(`No se pudo obtener totales de tarjetas, error:  ${next.Error.Code}-${next.Error.Message}`);
      }
    }, error => {
      this.alertService.errorInfoAlert(`No se pudo obtener totales de tarjetas, error: ${error}`);
    });
  }
  // Verifica si el usuario tiene permiso para acceder a la pagina
  CheckPermits() {
    this.permService.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        data.perms.forEach(Perm => {
          if (Perm.Name === 'V_Balance') {
            this.permisos = Perm.Active;
          }
        });
      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
    });
  }
}
