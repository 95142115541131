import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { ElectronRendererService } from 'src/app/electronrenderer.service';
import { BoRcptTypes, PaymentResults } from 'src/app/enum/enum';
import { AppConstants, Company, ITransaction, IPrinter, ITerminal, IVoidedTransaction } from 'src/app/models';
import { IContableAccounts } from 'src/app/models/i-contableaccounts';
import { IInvoiceInfoForPayment, IOnPaymentFail } from 'src/app/models/i-payment-data';
import { BasePayment, CreditCards } from 'src/app/models/i-payment-document';
import { PinpadPayLoad, } from 'src/app/models/i-pp-transaction';
import { AlertService, AuthenticationService, PaymentService, ReportsService, StorageService } from 'src/app/services';
const VAT: number = 0.04;
// CONSTANTS
const printJS = require("print-js");
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  providers: [DatePipe]
})
export class PaymentComponent implements OnInit {
  //varbox
  requiredCardAccount: string;
  requiredCashAccount: string;
  requiredTransferAccount: string;
  defaultCardNumber: string;
  isAllowedToEditCardNumber: boolean;
  maxAmountToCharge: number;
  maxVatToRefunded: number;
  lockedTotalUnvatted: number;
  amountToCharge: number;
  amountToCommit: number;
  vatToCommit: number;
  theoricalAmount: number;
  theoricalVAT: number;

  unvattedAmount: number;
  // vatRefunded: number;
  hasVatApplied: boolean;
  isRefundedValid: boolean;
  terminal: ITerminal;
  userCurrency: string;
  exRate: number;


  @BlockUI() blockUI: NgBlockUI;

  @Input() public requiredData: any;

  TO_FIXED_TOTALDOCUMENT: string; // Contiene la cantidad de decimales a usar en el total del documento

  //Forms
  cashForm: FormGroup;
  transferForm: FormGroup;
  checkForm: FormGroup;
  creditCardForm: FormGroup;
  //Variables pp
  transaction: ITransaction;
  transactions: ITransaction[];
  terminalsByUser: ITerminal[];
  terminals: ITerminal[];
  pPTransaction: ITransaction;
  isRequestinBacPayment: boolean;

  preDocument: any;

  //Totales
  TotalG: number; // monto total del pago
  ReceivedG: number; // monto total recibido del pago
  DifferenceG: number; // monto total de la diferencia, o salto restante
  ChangeG: number; // guarda el vuelto
  TotalCash: number; // monto total del pago en efectivo
  TotalCards: number; // monto total del pago en tarjetas
  TotalTransfer: number; // monto total del pago en transferencia
  TotalCheck: number; // monto total del pago en cheque
  changeColones: number; // vuelto en colones
  changeDolares: number; // vuelto en dolares
  currencyChange: number; // monto del tipo de cambio
  totalUSD: number;
  total: number;


  //REVISAR ------------------------------------------------------
  isBilling: boolean;//Validacion flag para evitar reenvio de solicitudes al mismo tiempo

  currencyPayment: string;

  COMPANY: Company;

  currencyList: any[]; // lista de tipos de cambio
  accountList: IContableAccounts;
  V_CreditCards: CreditCards[]; // lista de pagos registrados con tarjetas de credito
  cardsList: any[]; // lista de tarjetas
  V_Checks: any[]; // Lista de pagos registrados con cheques
  banksList: any[]; // lista de bancos

  invoiceInfo: IInvoiceInfoForPayment;

  CreditCardsWithoutVATCARD: CreditCards[];

  isFromFail: boolean;
  DefaultCardValid: string;//Fecha valido hasta configurado desde companias

  flagForm: boolean;  //Validacion flag para evitar reenvio de solicitudes al mismo tiempo

  constructor(private fb: FormBuilder
    , private alertService: AlertService
    , private modalService: NgbModal
    , private datePipe: DatePipe
    , private storageService: StorageService
    , private authenticationService: AuthenticationService
    , private paymentService: PaymentService
    , private reportsService: ReportsService
    , private electronRendererService: ElectronRendererService
    , private router: Router) { }

  ngOnInit() {
    this.initData();
    this.LoadData();
    this.resetModalData();
    this.TO_FIXED_TOTALDOCUMENT = `1.${this.COMPANY.DecimalAmountTotalDocument}-${this.COMPANY.DecimalAmountTotalDocument}`;
  }

  theoricalDocumentAmount: number;
  theoricalDocumentVAT: number;

  theoricalDocumentAmountConverted: number;
  theoricalDocumentVatConverted: number;
  LoadData(): void {
    if (this.requiredData) {

      this.isAllowedToEditCardNumber = this.requiredData.IsAllowedToEditCardNumber;
      this.requiredCashAccount = this.requiredData.RequiredCashAccount;
      this.requiredTransferAccount = this.requiredData.RequiredTransferAccount;
      this.requiredCardAccount = this.requiredData.RequiredCardAccount;

      this.amountToCharge = this.requiredData.DocumentTotals.TotalUnvatted; // Contiene el monto a cobrar sin importa el medio de pago
      this.theoricalVAT = this.requiredData.DocumentTotals.VatRefunded; // contiene solo el impuesto 4iva
      this.theoricalAmount = this.requiredData.DocumentTotals.UnVattedAmount; // contiene el monto a cobrar del 4iva

      this.userCurrency = this.requiredData.UserCurrency;
      this.exRate = +this.requiredData.ExRate;

      this.isFromFail = false;
      this.invoiceInfo = this.requiredData.InvoiceInfo;
      //Listas
      this.V_CreditCards = this.requiredData.lists.V_CreditCards.filter(x => x.VatRefunded != 0);
      this.accountList = this.requiredData.lists.accountList;
      this.currencyList = this.requiredData.lists.currencyList;
      this.cardsList = this.requiredData.lists.cardsList;
      this.banksList = this.requiredData.lists.banksList;
      this.V_Checks = this.requiredData.lists.V_Checks;
      //Tipo cambio expresado en COL/USD/EUR
      this.currencyPayment = this.requiredData.Currency.currencyPayment;
      //Totals
      this.currencyChange = this.requiredData.DocumentTotals.currencyChange;
      this.totalUSD = this.requiredData.DocumentTotals.totalUSD;
      this.TotalG = this.total = this.requiredData.DocumentTotals.total;

      if (this.currencyPayment !== 'COL') {
        this.theoricalDocumentAmount = this.requiredData.DocumentTotals.UnVattedAmount;

        this.theoricalDocumentAmountConverted = this.requiredData.DocumentTotals.UnVattedAmount * this.currencyChange;

        this.theoricalDocumentVAT = this.requiredData.DocumentTotals.VatRefunded;
        this.theoricalDocumentVatConverted = this.requiredData.DocumentTotals.VatRefunded * this.currencyChange;
      } else {
        this.theoricalDocumentAmount = this.requiredData.DocumentTotals.UnVattedAmount;
        this.theoricalDocumentAmountConverted = this.requiredData.DocumentTotals.UnVattedAmount / this.currencyChange;

        this.theoricalDocumentVAT = this.requiredData.DocumentTotals.VatRefunded;
        this.theoricalDocumentVatConverted = this.requiredData.DocumentTotals.VatRefunded / this.currencyChange;
      }

      // PP, documento requerido para almacenarlo cuando se haga un pago por PP
      this.preDocument = this.requiredData.PinpadInfo.PreDocument;
      this.DefaultCardValid = this.requiredData.CardValid;
      this.defaultCardNumber = this.requiredData.DefaultCardNumber;

      this.terminal = this.terminalsByUser.find(x => x.Currency == this.currencyPayment && x.IsDefault);
      !this.terminal || this.creditCardForm.patchValue({ Terminal: this.terminal.TerminalId})

      // OnFail, si algo salio mal creando el documento al cual se le hizo el pago
      if (this.requiredData.OnFail.IsFail) {
        this.isFromFail = true;

        let value: IOnPaymentFail = this.requiredData.OnFail.DataForFail;

        this.cashForm.patchValue(value.cashForm.value);

        this.creditCardForm.patchValue(value.creditCardForm.value);

        this.transferForm.patchValue(value.transferForm.value);

        this.V_CreditCards = [...value.CreditCards];

        this.transactions = [...value.Transactions];

        this.theoricalVAT = value.TheoricalVAT;

        this.DifferenceG = value.DifferenceG;

        this.ReceivedG = value.ReceivedG;

        this.TotalCards = value.TotalCards;

        this.TotalCash = value.TotalCash;

        this.TotalCheck = value.TotalCheck;

        this.TotalTransfer = value.TotalTransfer;

        this.setTotalAmount();

        this.changeCurrency(true);
      }
    }


  }


  // funcion para cambiar el tipo de moneda en la modal de pagos
  changeCurrency(_isOnLoad = false): void {

    const AMOUNT_APPLIED = 1

    if (!_isOnLoad) {
      this.creditCardForm.patchValue({ CreditSum: 0 });
      this.cashForm.patchValue({ TotalCash: 0 });
      this.transferForm.patchValue({ TransSum: 0 });
      this.alertService.infoInfoAlert(`Se ha restablecido el monto recibido del cliente`);

      this.terminal = this.terminalsByUser.find(x => x.Currency == this.currencyPayment && x.IsDefault);
      this.creditCardForm.patchValue({
        Terminal: this.terminal.TerminalId
      });
    }

    if (this.currencyPayment !== 'COL') {
      if (this.userCurrency === 'USD') {
        this.TotalG = this.total;
        this.ReceivedG = this.total;

        this.theoricalAmount = this.theoricalDocumentAmount;
        this.theoricalVAT = this.theoricalDocumentVAT;
      }
      else {
        this.theoricalAmount = this.theoricalDocumentAmountConverted;
        this.theoricalVAT = this.theoricalDocumentVatConverted;
        this.TotalG = Number((this.total / this.currencyChange).toFixed(2));
        this.ReceivedG = this.TotalG;
      }
    } else {
      if (this.userCurrency === 'COL') {
        this.TotalG = this.total;
        this.ReceivedG = this.TotalG;

        this.theoricalAmount = this.theoricalDocumentAmount;
        this.theoricalVAT = this.theoricalDocumentVAT;
      }
      else {
        this.TotalG = Number((this.total * this.currencyChange).toFixed(2));
        this.ReceivedG = this.TotalG;

        this.theoricalAmount = this.theoricalDocumentAmountConverted;
        this.theoricalVAT = this.theoricalDocumentVatConverted;
      }
    }

    this.ReceivedG = 0;
    this.setTotalAmount();
  }

  onCtrlBCash(): void {
    this.patchZeroes();
    this.cashForm.patchValue({ TotalCash: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument) });
    this.addAmountCash();
  }

  patchZeroes(): void {

  }

  addAmountCash(): void {
    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCash = parseFloat(this.cashForm.value.TotalCash);

    if (this.TotalCash > 0) {
      this.setTotalAmount();
    } else {
      this.cashForm.patchValue({ TotalCash: 0 });
      this.TotalCash = 0;
      this.setTotalAmount();
    }
  }

  // funcion para calcular el total de recibido y de diferencia para el pago
  setTotalAmount(): void { //decimalPipe
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.ReceivedG = Number((this.TotalCash + this.TotalCards + this.TotalCheck + this.TotalTransfer).toString());
    this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

    let diff = Number((this.TotalG - this.ReceivedG).toString());

    this.DifferenceG = Math.max(diff, 0.0);
    this.ChangeG = Math.max(0, -1 * diff);

    if (this.currencyPayment !== 'COL') {
      this.changeDolares = this.ChangeG;
      this.changeColones = Number((this.ChangeG * this.currencyChange).toString());
    }
    else {
      this.changeDolares = Number((this.ChangeG / this.currencyChange).toString());;
      this.changeColones = this.ChangeG;
    }
  }

  // funcion para colocar el monto a pagar en tranferencia
  addAmountTransfer(): void {
    this.TotalTransfer = parseFloat(this.transferForm.value.TransSum);
    this.TotalTransfer = +this.TotalTransfer.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCards = +this.TotalCards.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalCheck = +this.TotalCheck.toFixed(this.COMPANY.DecimalAmountTotalDocument);
    this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

    if (this.TotalTransfer > 0) {
      if ((this.TotalCards + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {
        this.setTotalAmount();
      } else {
        this.alertService.infoInfoAlert('El monto ingresado en transferencia supera el total de la factura.');
        this.transferForm.patchValue({ TransSum: 0 });
        this.TotalTransfer = 0;
        this.setTotalAmount();
      }
    } else {
      this.transferForm.patchValue({ TransSum: 0 });
      this.TotalTransfer = 0;
      this.setTotalAmount();
    }
  }

  addAmounCreditCard() {

    if (this.requiredCardAccount && +this.creditCardForm.value.CreditSum > 0 && this.creditCardForm.value.CreditCard == '') {
      this.alertService.infoAlert(`Por favor seleccione el nombre de la tarjeta antes de enviar el pago`);
      return;
    }

    if (!this.creditCardForm.value.CardNum) {
      this.alertService.infoAlert(`Por favor digite un número de tarjeta válido`);
      return;
    }
    let totalcard = 0;
    if (this.creditCardForm.valid) {
      if (parseFloat(this.creditCardForm.value.CreditSum) > 0) {
        if (this.V_CreditCards.length > 0) {
          this.V_CreditCards.forEach((vcc) => {
            totalcard += parseFloat((vcc.CreditSum + (vcc.RefundedVAT || 0)).toString());
          });
        }

        const AVAILABLE_THEORICAL_AMOUNT = this.theoricalAmount - this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0);

        const AMOUNT_TO_CHARGE_ON_CARD = this.creditCardForm.value.CreditSum <= AVAILABLE_THEORICAL_AMOUNT ? this.creditCardForm.value.CreditSum : AVAILABLE_THEORICAL_AMOUNT;

        let percetageUsedFromMaxAmount = AMOUNT_TO_CHARGE_ON_CARD / this.theoricalAmount;

        if (percetageUsedFromMaxAmount > 1) {
          percetageUsedFromMaxAmount = (this.creditCardForm.value.CreditSum + this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0)) / this.theoricalAmount;
          this.alertService.infoAlert(`El monto es superior al total del documento`);
          return;
        }

        const VAT_TO_REFUND = Number((this.theoricalVAT * percetageUsedFromMaxAmount).toFixed(this.COMPANY.DecimalAmountTotalDocument));

        if ((totalcard + VAT_TO_REFUND + this.creditCardForm.value.CreditSum + this.TotalCash + this.TotalTransfer) > this.TotalG) {
          this.alertService.infoInfoAlert("El el monto a cobrar con tarjeta supera el total de la factura. Monto a cobrar actualizado.");
          return;
        }


        totalcard += this.creditCardForm.value.CreditSum;

        totalcard = Number(totalcard.toFixed(this.COMPANY.DecimalAmountTotalDocument));

        if (totalcard > 0) {
          if ((totalcard + this.TotalTransfer + this.TotalCheck) <= this.TotalG) {

            const CREDIT_CARD = this.cardsList.filter(x => x.CreditCard === this.creditCardForm.value.CreditCard)[0];
            // Revisar ----------
            let temp = this.creditCardForm.value.CardValid.split('-');
            let aux = temp[1] + '/' + temp[0][2] + temp[0][3];

            this.V_CreditCards.push({
              CardValid: aux,
              CreditCardNumber: this.creditCardForm.value.CardNum,
              CreditCard: CREDIT_CARD.CardName.split(' ')[0],
              CreditSum: this.creditCardForm.value.CreditSum,
              U_ManualEntry: this.creditCardForm.value.IsManualEntry ? '1' : '0',
              FormatCode: CREDIT_CARD.CardName,
              OwnerIdNum: this.creditCardForm.value.IsManualEntry ? this.creditCardForm.value.VoucherNum : this.creditCardForm.value.OwnerIdNum,
              VoucherNum: this.creditCardForm.value.VoucherNum,
              IsManualEntry: this.creditCardForm.value.IsManualEntry,
              CreditAcct: CREDIT_CARD.CreditCard,
              RefundedVAT: VAT_TO_REFUND
            } as CreditCards);

            this.TotalCards = totalcard;

            this.setTotalAmount();

            totalcard += (this.V_CreditCards[this.V_CreditCards.length - 1].RefundedVAT || 0);

            this.TotalCards = totalcard;

            this.setTotalAmount();

            // proceso que se ejecuta condicionalmente es para ocultar el input de iva devuelto
            if (this.theoricalVAT > 0) this.CheckVatApplied();

            this.creditCardForm.patchValue({ OwnerIdNum: '0' });
            this.creditCardForm.patchValue({ VoucherNum: '0' });
            this.creditCardForm.patchValue({ CardValid: this.DefaultCardValid });
            this.creditCardForm.patchValue({ CardNum: this.defaultCardNumber });
            this.creditCardForm.patchValue({ CreditSum: '0' });
            this.creditCardForm.patchValue({ IsManualEntry: true });

            this.onCtrlBTarjeta();


          } else {
            this.creditCardForm.patchValue({ CreditSum: '0' });
            this.alertService.infoInfoAlert('El monto ingresado en la tarjeta de crédito supera el total de la factura.');
          }
        } else {
          this.alertService.infoInfoAlert(
            "El monto ingresado en Total es incorrecto."
          );
          this.creditCardForm.patchValue({ CreditSum: 0 });
        }
      } else {
        this.creditCardForm.patchValue({ CreditSum: 0 });
      }
    } else {
      this.alertService.infoInfoAlert("Campos inválidos");
    }
  }

  removeCreditCard(_index, _voucherNumber: string, _ownerIdNum: string = ``) {

    let transaction: ITransaction = null;

    (this.transactions || []).forEach((x, index) => {
      if (index === _index && x.AuthorizationNumber === _ownerIdNum) transaction = x
    });

    if (transaction) {
      this.blockUI.start(`Procesando, espere por favor`);

      let commitedVoidedTransaction: IVoidedTransaction = null;
      let serializedTransaction = ``;
      this.storageService.StoreTransaction(`STG`, `VD`, JSON.stringify(transaction), this.invoiceInfo.uniqueInvCode)

      this.paymentService.CancelCommitedTransaction(transaction).pipe(
        finalize(() => {
          this.blockUI.stop();
          this.commitVoidedTransaction(commitedVoidedTransaction, serializedTransaction);
        })
      ).subscribe(next => {
        try {
          if (next.Result) {
            this.TotalCards -= (this.V_CreditCards[_index].CreditSum + (this.V_CreditCards[_index].RefundedVAT || 0));

            this.setTotalAmount();

            if (this.theoricalAmount > 0) this.CheckVatApplied();

            if (this.V_CreditCards.length === 0) {
              this.isRefundedValid = false;
            }


            this.creditCardForm.patchValue({
              'CardValid': this.DefaultCardValid,
              'CardNum': this.defaultCardNumber,
              'OwnerIdNum': '0',
              'VoucherNum': '0',
              'IsManualEntry': true
            });
            this.pPTransaction = null;

            const CREDIT_SUM = this.V_CreditCards[_index].CreditSum;

            if (this.theoricalAmount > 0) {
              if (this.theoricalAmount >= (this.maxAmountToCharge + CREDIT_SUM)) {
                this.maxAmountToCharge += CREDIT_SUM
              }
              this.onCtrlBTarjeta();
            }

            this.V_CreditCards.splice(_index, 1);

            const INDEX_TRANSACTION = this.transactions.findIndex(x => x.SystemTrace === transaction.SystemTrace)

            const EMVS_STREAM = JSON.parse(next.Data)['EMVStreamResponse'];

            this.transactions.splice(INDEX_TRANSACTION, 1);

            this.setTotalAmount();

            serializedTransaction = next.Data;

            commitedVoidedTransaction = {
              InvoiceNumber: transaction.InvoiceNumber,
              SerializedTransaction: next.Data,
              TerminalId: transaction.TerminalId,
              TransactionId: EMVS_STREAM.transactionId
            } as IVoidedTransaction;

            this.storageService.StoreTransaction(`CMT`, `VD`, JSON.stringify(commitedVoidedTransaction), this.invoiceInfo.uniqueInvCode);
          }
          else {
            this.storageService.StoreTransaction(`SRT`, `VD`, `${next.Error.Code} - ${next.Error.Message}`, this.invoiceInfo.uniqueInvCode);
            this.alertService.ErrorAlert(`${next.Error.Code} - ${next.Error.Message}`);
          }
        } catch (error) {
          this.storageService.StoreTransaction(`SRT`, `VD`, AppConstants.GetError(error), this.invoiceInfo.uniqueInvCode);
          this.alertService.ErrorAlert(AppConstants.GetError(error));
        }
      }, error => {
        this.storageService.StoreTransaction(`SRT`, `VD`, AppConstants.GetError(error), this.invoiceInfo.uniqueInvCode);
        this.alertService.ErrorAlert(AppConstants.GetError(error));
      });
    }
    else {
      const CREDIT_SUM = this.V_CreditCards[_index].CreditSum;
      this.TotalCards -= (this.V_CreditCards[_index].CreditSum + (this.V_CreditCards[_index].RefundedVAT || 0));
      this.V_CreditCards.splice(_index, 1);
      this.setTotalAmount();

      if (this.theoricalAmount > 0) {
        if (this.theoricalAmount >= (this.maxAmountToCharge + CREDIT_SUM)) {
          this.maxAmountToCharge += CREDIT_SUM
        }
        this.onCtrlBTarjeta();
      }

      if (this.theoricalAmount > 0) this.CheckVatApplied();

      if (this.V_CreditCards.length === 0) {
        this.isRefundedValid = false;
      }
    }
  }

  commitVoidedTransaction(_voidedTransaction: IVoidedTransaction, _serializedTransaction: string): void {

    if (!_voidedTransaction) return; // Its no required to notify to the user an uncommited transaction

    this.blockUI.start(`Procesando, espere por favor`);

    let commitedVoidedTransaction: IVoidedTransaction = null
    this.paymentService.CommitCanceledCard(_voidedTransaction).pipe(
      finalize(() => {
        this.blockUI.stop();
        this.PrintVoid(_serializedTransaction, commitedVoidedTransaction.InvoiceNumber, commitedVoidedTransaction.TerminalId, commitedVoidedTransaction.CreationDate);
      })
    ).subscribe({
      next: (callback) => {
        if (callback.Result) {
          commitedVoidedTransaction = callback.Data;
          commitedVoidedTransaction.CreationDate = (new Date()).toLocaleString();
          this.alertService.successInfoAlert(`Transacción completada`, 2000);
        }
        else {
          this.alertService.infoAlert(`Transacción anulada pero no se pudo respaldar en base de datos. ${AppConstants.GetError(callback)}`);
        }
      },
      error: error => {
        console.info(error);
        this.alertService.errorAlert(AppConstants.GetError(error));
      }
    });
  }


  PrintVoid(_serializedTransaction: string, _invoiceNumber: string, _terminal: string, _creationDate: string) {
    const EMVSTREAM = JSON.parse(_serializedTransaction)['EMVStreamResponse'];

    const TRANSACTION = {
      AuthorizationNumber: EMVSTREAM['authorizationNumber'],
      InvoiceNumber: _invoiceNumber,
      ReferenceNumber: EMVSTREAM['referenceNumber'],
      SystemTrace: EMVSTREAM['systemTraceNumber'],
      TerminalId: _terminal
    } as ITransaction;

    // Se usa esta forma de parsear el monto debido que para el bac 1000, representa 10.00 colones
    const AMOUNT = +EMVSTREAM.salesAmount.slice(0, EMVSTREAM.salesAmount.length - 2) + "." + EMVSTREAM.salesAmount.slice(-2);

    const TERMINAL:ITerminal = this.terminals.find(x => x.TerminalId == _terminal) || {} as ITerminal;

    // make some mappings
    let docEntry = -1; // i set this value to -1 because is used to ignore some fields in the report
    let rawData = `>ct:1>ct_end>tr1:${_terminal}>tr_end1>am1:${AMOUNT}>am_end1>ti1:${_invoiceNumber}`;
    rawData += `>ti_end1>st1:${EMVSTREAM['systemTraceNumber']}>st_end1>rn1:${EMVSTREAM['referenceNumber']}`;
    rawData += `>rn_end1>na1:${EMVSTREAM['authorizationNumber']}>na_end1>cu1:${TERMINAL.Currency}>cu_end1>fc1:${_creationDate}>fc_end1`;

    this.reportsService.PrintVoucher(docEntry, rawData).pipe(finalize(() => this.blockUI.stop())).subscribe(data => {
      try {

        if (data.Result) {
          if (this.electronRendererService.CheckElectron()) {
            let fileName = 'Invoice_' + TRANSACTION.InvoiceNumber + '.pdf';
            const PRINTERCONFIGURATION = JSON.parse(this.storageService.getCompanyConfiguration().PrinterConfiguration) as IPrinter;
            let file = {
              "fileName": fileName,
              "file": data.Data,
              "defaultPrinter": PRINTERCONFIGURATION.DisplayName
            };
            this.electronRendererService.send('Print', file);
          }
          else {
            printJS({
              printable: data.Data,
              type: 'pdf',
              base64: true
            });
          }
        } else {
          this.alertService.errorInfoAlert(`Error obteniendo reporte, error: ${data.Error.Code}-${data.Error.Message}`);
        }

      } catch (error) {
        console.info(error);
      }

    }, error => {

      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
    });
  }


  PrintCanceledVoucher(): void {
    // throw new Error("Method not implemented.");
  }


  onCtrlBTransferencia(): void {
    this.patchZeroes();
    this.transferForm.patchValue({ TransSum: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument) });
    this.addAmountTransfer();
  }

  onCtrlBCheque(): void {
    this.patchZeroes();
    this.checkForm.patchValue({ CheckSum: this.DifferenceG.toFixed(this.COMPANY.DecimalAmountTotalDocument) });
  }

  onCtrlBTarjeta(): void {

    this.patchZeroes();

    this.creditCardForm.patchValue({ CreditSum: 0 });

    const DIFFERENCE_ON_CARDS = +(this.maxAmountToCharge - this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0)).toFixed(this.COMPANY.DecimalAmountTotalDocument);

    if (this.theoricalVAT > 0 && this.creditCardForm.value.CreditSum > 0 && DIFFERENCE_ON_CARDS > 0) {
      this.alertService.infoInfoAlert(`Monto restante actualizado`);
    }

    this.creditCardForm.patchValue({ CreditSum: this.PreviewUnvattedAmount() });
  }
  PreviewUnvattedAmount(): number {
    try {
      this.maxAmountToCharge = 0;

      this.maxVatToRefunded = 0;

      const OTHER_PAMENTS = Number((this.TotalTransfer + this.TotalCash + this.TotalCards).toFixed(this.COMPANY.DecimalAmountTotalDocument));

      const THEORICAL_VATTED_AMOUNT = this.theoricalAmount + this.theoricalVAT;

      const THEORICAL_TOTAL = this.amountToCharge + THEORICAL_VATTED_AMOUNT;

      let THEORICAL_CHARGE = THEORICAL_TOTAL - OTHER_PAMENTS;

      let theoricalLessThan = 0;
      let theoricalVATLessThan = 0;
      let theoricalEqualsTo = 0;
      let theoricalVATEqualsTo = 0;
      let theoricalGreaterThan = 0;
      let theoricalVATGreaterThan = 0;

      if (THEORICAL_CHARGE < THEORICAL_VATTED_AMOUNT) {
        theoricalLessThan = THEORICAL_CHARGE / 1.04;//this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0) > 0 ? THEORICAL_CHARGE - this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0) : THEORICAL_CHARGE - THEORICAL_CHARGE * VAT;
        theoricalVATLessThan = theoricalLessThan * VAT;
      }

      if (THEORICAL_CHARGE === THEORICAL_VATTED_AMOUNT) {
        theoricalEqualsTo = this.theoricalAmount;
        theoricalVATEqualsTo = this.theoricalVAT;
      }

      if (THEORICAL_CHARGE > THEORICAL_VATTED_AMOUNT) {
        theoricalGreaterThan = (this.theoricalVAT + this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0)) > 0 ? THEORICAL_CHARGE - (this.theoricalVAT - this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0)) : THEORICAL_CHARGE;
        theoricalVATGreaterThan = (this.theoricalVAT - this.V_CreditCards.reduce((acc, card) => acc + card.RefundedVAT, 0));
      }

      const MAX_CHARGE_ON_CARDS = +(theoricalLessThan + theoricalEqualsTo + theoricalGreaterThan).toFixed(this.COMPANY.DecimalAmountTotalDocument);
      const MAX_VAT_REFUNDED = +(theoricalVATLessThan + theoricalVATEqualsTo + theoricalVATGreaterThan).toFixed(this.COMPANY.DecimalAmountTotalDocument);

      this.maxAmountToCharge = MAX_CHARGE_ON_CARDS;

      this.maxVatToRefunded = MAX_VAT_REFUNDED;

      const THEORICAL_TO_CHARGE = MAX_CHARGE_ON_CARDS >= 0 ? MAX_CHARGE_ON_CARDS : 0; //- ; +(MAX_CHARGE_ON_CARDS - this.V_CreditCards.reduce((acc, card) => acc + card.CreditSum, 0) > 0 ?  MAX_CHARGE_ON_CARDS : MAX_CHARGE_ON_CARDS).toFixed(this.COMPANY.DecimalAmountTotalDocument)  ;

      return THEORICAL_TO_CHARGE;
    }
    catch (error) {
      return 0;
    }
  }

  SubstractTaxFromAmount(_amount: number): number {
    try {
      const UNVATTED_AMOUNT_PERCENTAGE = _amount / this.theoricalAmount;

      const SUBSTRACTED_VAT = this.theoricalVAT * UNVATTED_AMOUNT_PERCENTAGE;

      return SUBSTRACTED_VAT;
    }
    catch (error) {
      return 0;
    }
  }

  SubstactAmountFromTax(_amount: number): number {
    try {
      return this.theoricalVAT > 0 ? +(_amount - (_amount * VAT)).toFixed(this.COMPANY.DecimalAmountTotalDocument) : _amount
      return
    }
    catch (error) {
      return 0;
    }
  }

  // GetVatRefunded(): number {
  //   try {


  //     return +(this.creditCardForm.value.CreditSum * VAT).toFixed(this.COMPANY.DecimalAmountTotalDocument);
  //     // este codigo de aqui para abajo lo dejo porque lo tengo que revisar

  //     this.TotalG = +this.TotalG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

  //     this.ReceivedG = +this.ReceivedG.toFixed(this.COMPANY.DecimalAmountTotalDocument);

  //     const DIFFERENCE_ON_PAYMENT_METHODS = this.DifferenceG - this.vatRefunded;

  //     let REAL_TOTAL_ON_CARD = DIFFERENCE_ON_PAYMENT_METHODS >= 0 ? this.vatRefunded : this.DifferenceG;

  //     let amountOnCard = 0;
  //     if (DIFFERENCE_ON_PAYMENT_METHODS === 0) {
  //       amountOnCard = REAL_TOTAL_ON_CARD - ((REAL_TOTAL_ON_CARD / (1 + (4 / 100))) * (4 / 100));
  //     }
  //     else if (DIFFERENCE_ON_PAYMENT_METHODS > 0) {
  //       amountOnCard = REAL_TOTAL_ON_CARD - ((REAL_TOTAL_ON_CARD / (1 + (4 / 100))) * (4 / 100));
  //     }
  //     else {
  //       amountOnCard = REAL_TOTAL_ON_CARD;
  //     }

  //     if (+this.creditCardForm.value.CreditSum > 0 && amountOnCard !== +this.creditCardForm.value.CreditSum) {
  //       amountOnCard = +this.creditCardForm.value.CreditSum;
  //     }

  //     let VAT_REFUNDED = amountOnCard * 0.04;

  //     // percentage secction calculus


  //     const UNVATTED_AMOUNT = this.vatRefunded - ((this.vatRefunded / (1 + (4 / 100))) * (4 / 100));

  //     const PERCENTAGE_CHARGED = +this.creditCardForm.value.CreditSum / UNVATTED_AMOUNT;

  //     if (VAT_REFUNDED * PERCENTAGE_CHARGED === VAT_REFUNDED) {
  //       return VAT_REFUNDED;
  //     }

  //     return +(VAT_REFUNDED - (VAT_REFUNDED * PERCENTAGE_CHARGED)).toExponential(this.COMPANY.DecimalAmountTotalDocument);
  //   }
  //   catch (error) {
  //     console.info(error);
  //     return 0;
  //   }
  // }

  GetVATAmountUsed(_creditSum: number): number {
    return ((_creditSum * 1.04) - _creditSum);
  }

  closePayModal(): void {
    if (this.V_CreditCards.length > 0 && !this.isBilling) {
      this.alertService.infoInfoAlert(`Elimine las tarjetas agregadas`);
      return;
    }

    // console.log('payments', this.V_CreditCards);
    // console.log('payments', this.isBilling);

    let closeDetail = { status: PaymentResults.CancelButton, DismissReason: 'Cancelar' };
    this.modalService.dismissAll(closeDetail);
    //this.isOnSubmit = false;
  }

  resetModalData(): void {
    if (this.isFromFail) return;
    this.isRefundedValid = false;
    this.TotalCash = 0;
    this.TotalCards = 0;
    this.TotalCheck = 0;
    this.TotalTransfer = 0;
    this.ReceivedG = 0;
    this.DifferenceG = this.TotalG;
    this.ChangeG = 0;


    this.cashForm.patchValue({ AccountCash: this.accountList.CashAccounts[0].Account });
    this.cashForm.patchValue({ TotalCash: 0 });

    this.creditCardForm.patchValue({ CreditCard: this.cardsList[0].CreditCard });
    this.creditCardForm.patchValue({ CreditSum: 0 });
    this.creditCardForm.patchValue({ IsManualEntry: true });
    this.creditCardForm.patchValue({ VoucherNum: '0' });
    this.creditCardForm.patchValue({ CardValid: this.DefaultCardValid });
    this.creditCardForm.patchValue({ CardNum: this.defaultCardNumber });
    this.creditCardForm.patchValue({ OwnerIdNum: '0' });



    this.transferForm.patchValue({ AccountTransf: this.accountList.TransferAccounts[0].Account });
    this.transferForm.patchValue({ DateTrans: this.datePipe.transform(new Date(), 'y-MM-dd') });
    this.transferForm.patchValue({ Ref: '' });
    this.transferForm.patchValue({ TransSum: 0 });

    this.checkForm.patchValue({ AccountCheck: this.accountList.CheckAccounts[0].Account });
    this.checkForm.patchValue({ BankNames: this.banksList[0].BankCode });
    this.checkForm.patchValue({ DueDate: '' });
    this.checkForm.patchValue({ CountryCod: 'CR' });
    this.checkForm.patchValue({ AcctNum: '' });
    this.checkForm.patchValue({ CheckNum: '' });
    this.checkForm.patchValue({ CheckSum: 0 });
  }


  initData(): void {
    this.maxAmountToCharge = 0;
    this.maxVatToRefunded = 0;
    this.vatToCommit = 0;
    this.amountToCommit = 0;
    this.hasVatApplied = false;
    this.isRefundedValid = false;
    this.flagForm = false;
    this.terminal = this.storageService.GetTerminal() as ITerminal;
    this.transactions = [];
    this.transaction = null;
    this.currencyList = []; // lista de tipos de cambio

    this.CreditCardsWithoutVATCARD = [];
    this.V_CreditCards = []; // lista de pagos registrados con tarjetas de credito
    this.cardsList = []; // lista de tarjetas
    this.V_Checks = []; // Lista de pagos registrados con cheques
    this.banksList = []; // lista de bancos

    this.CheckVatApplied();
    this.terminals = this.storageService.GetPPTerminals();
    this.terminalsByUser = this.storageService.GetTerminalsbyUser();


    this.COMPANY = this.storageService.getCompanyConfiguration();

    let mDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.cashForm = this.fb.group({
      AccountCash: ["", Validators.required],
      TotalCash: [0, Validators.required],
    });

    this.transferForm = this.fb.group({
      AccountTransf: ["", Validators.required],
      DateTrans: [mDate, Validators.required],
      Ref: ["", Validators.required],
      TransSum: [0, Validators.required],
    });

    this.checkForm = this.fb.group({
      AccountCheck: ['', Validators.required],
      BankNames: ['', Validators.required],
      DueDate: ['', Validators.required],
      CountryCod: [{ value: 'CR', disabled: true }, Validators.required],
      AcctNum: ['', Validators.required],
      CheckNum: ['', Validators.required],
      CheckSum: [0, Validators.required]
    });

    this.creditCardForm = this.fb.group({
      CreditCard: ["", Validators.required],
      CardNum: [""], // , [Validators.required, Validators.minLength(4), Validators.maxLength(4)]
      OwnerIdNum: ["", Validators.required],
      CardValid: [""], //, [Validators.required, Validators.minLength(3), Validators.maxLength(6)]
      CreditSum: [0, Validators.required],
      VoucherNum: ["", Validators.required],
      IsManualEntry: [true, Validators.required],
      Terminal: [""]
    });
  }


  OnPaymentCreated(payment: any): void {
    let closeDetail = {
      status: PaymentResults.Created,
      Payment: payment,
      Changes: {
        USD: this.changeDolares,
        COL: this.changeColones,
        Recived: this.ReceivedG
      },
      PinpadInfo: {
        pinPadCards: this.transactions,
        terminals: this.terminals,
        pPTransaction: this.pPTransaction
      },
      OnFail: this.CreateOnFailObject(),
      DismissReason: 'Ok'
    }


    this.modalService.dismissAll(closeDetail);
  }


  // Hace la peticion de pago al api local para uso del pin pad
  requestPinPadPayment(): void {
    if (this.flagForm) {
      console.log('Intento duplicación pago pinpad');
      return;
    }

    if (!this.creditCardForm.controls.Terminal.value) {
      this.alertService.infoInfoAlert(`Seleccione un terminal por favor`);
      return;
    }

    this.terminal = this.terminalsByUser.find(x => x.TerminalId === this.creditCardForm.controls.Terminal.value);

    if (!this.terminal) {
      this.alertService.infoInfoAlert(`No se puedo obtener el terminal, recargue la página por favor`);
      return;
    }

    if (!this.terminal || Object.keys(this.terminal).length === 0) {
      this.alertService.warningInfoAlert(`No existe un terminal configurado para el usuario`);
      this.isRequestinBacPayment = false;
      return;
    }

    const AMOUNT = +this.creditCardForm.value.CreditSum;

    if (AMOUNT <= 0) {
      this.alertService.infoInfoAlert(`El monto a cobrar debe ser superior a 0`);
      return;
    }

    let totalcard = 0;

    if (!(parseFloat(this.creditCardForm.value.CreditSum) > 0)) {
      this.creditCardForm.patchValue({ CreditSum: 0 });
      this.alertService.infoInfoAlert("El monto total debe ser superior a cero.");
      return;
    }

    const PERCENTAGE_USED_FROM_MAX_AMOUNT = (this.creditCardForm.value.CreditSum) / this.maxAmountToCharge;

    if (PERCENTAGE_USED_FROM_MAX_AMOUNT > 1) {
      this.alertService.infoAlert(`El monto es superior al total del documento`);
      return;
    }

    const VAT_TO_REFUND = this.maxVatToRefunded * PERCENTAGE_USED_FROM_MAX_AMOUNT;


    if ((totalcard + VAT_TO_REFUND + this.creditCardForm.value.CreditSum + this.TotalCash + this.TotalTransfer) > this.TotalG) {
      this.alertService.infoInfoAlert("El el monto a cobrar con tarjeta supera el total de la factura. Monto a cobrar actualizado.");
      return;
    }

    if (this.V_CreditCards.length > 0) {
      this.V_CreditCards.forEach((vcc) => {
        totalcard += parseFloat(vcc.CreditSum.toString());
      });
      totalcard += parseFloat(this.creditCardForm.value.CreditSum);
    } else {
      totalcard = parseFloat(this.creditCardForm.value.CreditSum);
    }

    totalcard = Number(totalcard.toFixed(this.COMPANY.DecimalAmountTotalDocument));

    if (!(totalcard > 0)) {
      this.alertService.infoInfoAlert("El monto ingresado en Total es incorrecto.");
      return;
    }

    if (!((totalcard + this.TotalTransfer + this.TotalCheck) <= this.TotalG)) {
      this.creditCardForm.patchValue({ CreditSum: 0 });
      this.alertService.infoInfoAlert('El monto ingresado en la tarjeta de crédito supera el total de la factura.');
      return;
    }

    this.isRequestinBacPayment = true;

    if (this, this.currencyPayment !== this.terminal.Currency) {
      this.alertService.infoAlert(`El terminal sólo permite cobrar en ${this.terminal.Currency}`);
      return;
    }

    this.blockUI.start(`Procesando, espere por favor`);

    this.flagForm = true;

    const USER_NAME = JSON.parse(this.storageService.getCurrentSession()).UserName;

    const INVOICE_NUMBER = this.BacIdGenerator();

    const STARTED_TRANSACTION = {
      Charge: AMOUNT,
      Currency: this.currencyPayment,
      InvoiceNumber: this.invoiceInfo.uniqueInvCode,
      Document: JSON.stringify(this.invoiceInfo),
      DocumentKey: this.invoiceInfo.uniqueInvCode,
      Terminal: this.terminal.TerminalId,
      User: USER_NAME
    } as PinpadPayLoad;

    this.storageService.StoreTransaction(`STG`, `SL`, JSON.stringify(STARTED_TRANSACTION), this.invoiceInfo.uniqueInvCode);

    this.paymentService.RequestPinPadPayment(AMOUNT, this.invoiceInfo.uniqueInvCode, this.currencyPayment, INVOICE_NUMBER, this.terminal.TerminalId)
      .pipe(finalize(() => {
        this.flagForm = false;
        this.isRequestinBacPayment = false;
        this.blockUI.stop();
      })).subscribe(next => {
        this.flagForm = false;
        try {
          if (next.Result) {
            const EMVS_STREAM = JSON.parse(next.Data)['EMVStreamResponse'];
            this.creditCardForm.patchValue({ 'CardNum': EMVS_STREAM['maskedCardNumber'].slice(-4) });
            this.creditCardForm.patchValue({ 'OwnerIdNum': EMVS_STREAM['authorizationNumber'] });
            this.creditCardForm.patchValue({ 'VoucherNum': EMVS_STREAM['referenceNumber'] });
            this.creditCardForm.patchValue({ 'IsManualEntry': false });

            this.setTotalAmount();

            const TRANSACTION = {
              InvoiceNumber: INVOICE_NUMBER,
              TerminalId: this.terminal.TerminalId,
              SerializedTransaction: next.Data,
              AuthorizationNumber: EMVS_STREAM['authorizationNumber'],
              ReferenceNumber: EMVS_STREAM['referenceNumber'],
              SystemTrace: EMVS_STREAM['systemTraceNumber'],
              TransactionId: EMVS_STREAM['transactionId']
            } as ITransaction;

            this.transactions.push(TRANSACTION);

            this.storageService.StoreTransaction(`CMT`, `SL`, next.Data, STARTED_TRANSACTION.DocumentKey);

            this.TotalCards = totalcard;

            this.setTotalAmount();

            if (this.theoricalVAT > 0) this.CheckVatApplied();

            document.getElementById('raiseCardPayment').click();

            this.alertService.successInfoAlert(`Transacción completada`, 2000);
          }
          else {
            this.creditCardForm.patchValue({ CreditSum: 0 });
            this.storageService.StoreTransaction(`SRT`, `SL`, `${next.Error.Code} ${next.Error.Message}`, STARTED_TRANSACTION.DocumentKey);
            this.alertService.ErrorAlert(`${next.Error.Code} ${next.Error.Message}`);
          }
        }
        catch (error) {
          this.alertService.ErrorAlert(`${AppConstants.GetError(error)}`);
          this.storageService.StoreTransaction(`SRT`, `SL`, `${AppConstants.GetError(error)}`, STARTED_TRANSACTION.DocumentKey);
        }
      }, error => {
        this.alertService.ErrorAlert(`${AppConstants.GetError(error)}`);
        this.storageService.StoreTransaction(`SRT`, `SL`, `${AppConstants.GetError(error)}`, STARTED_TRANSACTION.DocumentKey);
      });
  }

  CreatePaymentObject() {

    let total = 0;
    total = this.total;

    const VAT_RETURNED = +(this.V_CreditCards.reduce((acc, creditCard) => { return acc + (creditCard.RefundedVAT || 0) }, 0)).toFixed(this.COMPANY.DecimalAmountTotalDocument);

    this.CreditCardsWithoutVATCARD = [...this.V_CreditCards];

    if (this.theoricalVAT > 0 && VAT_RETURNED > 0) {

      const VAT_CARD: CreditCards = this.storageService.GetVATCard();

      this.V_CreditCards.push({
        CardValid: VAT_CARD.CardValid,
        CreditAcct: VAT_CARD.CreditAcct,
        CreditCard: VAT_CARD.CreditCard,
        CreditCardNumber: VAT_CARD.CreditCardNumber,
        CreditSum: VAT_RETURNED,
        IsManualEntry: VAT_CARD.IsManualEntry,
        OwnerIdNum: VAT_CARD.OwnerIdNum,
        U_ManualEntry: VAT_CARD.U_ManualEntry,
        VoucherNum: VAT_CARD.VoucherNum,
        RefundedVAT: VAT_CARD.RefundedVAT,
        FormatCode: VAT_CARD.FormatCode
      } as CreditCards);

    }

    let Payments: BasePayment = {
      CardCode: this.invoiceInfo.CardCode,
      CashAccount: this.cashForm.value.AccountCash,
      CashSum: this.cashForm.value.TotalCash - this.ChangeG,
      Remarks: this.invoiceInfo.Comment,
      DocCurrency: this.currencyPayment,
      DocRate: this.currencyChange,
      Total: total,
      //PaymentChecks: this.V_Checks,
      PaymentCreditCards: this.V_CreditCards,
      PaymentInvoices: [],
      TransferSum: this.transferForm.value.TransSum,
      TransferAccount: this.transferForm.value.AccountTransf,
      TransferDate: this.transferForm.value.DateTrans,
      TransferReference: this.transferForm.value.Ref,
      accountPayment: this.invoiceInfo.accountPayment,
      DocDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      DueDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      Series: -1,
      DocType: BoRcptTypes.rCustomer,
      UDFs: [],
      CheckAccount: '',
      CounterReference: '',
      U_MontoRecibido: this.ReceivedG
    };

    Payments['Transactions'] = this.transactions;

    return Payments;
  }


  CreateOnFailObject(): IOnPaymentFail {
    return {

      //Forms
      cashForm: this.cashForm,
      checkForm: this.checkForm,
      creditCardForm: this.creditCardForm,
      transferForm: this.transferForm,
      Transactions: [...this.transactions],

      //Variables de PP broken
      pPTransaction: null,
      pinPadCards: null,
      CreditCards: [...this.CreditCardsWithoutVATCARD],
      // Totales
      ChangeG: this.ChangeG,
      DifferenceG: this.DifferenceG,
      ReceivedG: this.ReceivedG,
      TotalCards: this.TotalCards,
      TotalCash: this.TotalCash,
      TotalCheck: this.TotalCheck,
      TotalTransfer: this.TotalTransfer,
      TheoricalVAT: this.theoricalVAT

    }

    // return {

    //   //Forms
    //   cashForm: this.cashForm,
    //   checkForm: this.checkForm,
    //   creditCardForm: this.creditCardForm,
    //   transferForm: this.transferForm,

    //   //Variables de PP broken
    //   pPTransaction: this.pPTransaction,
    //   pinPadCards: this.transactions,

    //   // Totales
    //   ChangeG: this.ChangeG,
    //   DifferenceG: this.DifferenceG,
    //   ReceivedG: this.ReceivedG,
    //   TotalCards: this.TotalCards,
    //   TotalCash: this.TotalCash,
    //   TotalCheck: this.TotalCheck,
    //   TotalTransfer: this.TotalTransfer

    // }
  }




  GeneratePreDocument(): string {

    const Payments = this.CreatePaymentObject();

    const JSONDocument = {
      'Invoice': this.preDocument,
      'Payment': { ...Payments, V_PaymentLines: this.requiredData.PinpadInfo.PrePaymentLines }
    }

    return JSON.stringify(JSONDocument);
  }


  BacIdGenerator(): string {
    const DATE = new Date();

    const DAYS = DATE.getDate() < 10 ? '0' + DATE.getDate() : DATE.getDate().toString();
    const MONTS = (DATE.getMonth() + 1) < 10 ? '0' + (DATE.getMonth() + 1) : (DATE.getMonth() + 1).toString();
    const YEAR = DATE.getFullYear();


    const HOURS = DATE.getHours() < 10 ? '0' + DATE.getHours() : DATE.getHours();
    const MINUTES = DATE.getMinutes() < 10 ? '0' + DATE.getMinutes() : DATE.getMinutes();
    const SECONDS = DATE.getSeconds() < 10 ? '0' + DATE.getSeconds() : DATE.getSeconds();

    const DATE_ = new Date(Date.UTC(YEAR, +DAYS, +MONTS, +HOURS, +MINUTES, +SECONDS));

    const PRE_FIX = DATE_.getTime() / 1000;

    const SUB_FIX = this.terminal.Id < 10 ? '0' + this.terminal.Id : this.terminal.Id;

    return SUB_FIX + '' + PRE_FIX;
  }

  CreatePay() {
    if (this.requiredCashAccount && this.TotalCash > 0 && this.cashForm.value.AccountCash == '') {
      this.alertService.infoAlert(`Por favor seleccione una cuenta de efectivo antes de enviar el pago`);
      return;
    }

    if (this.requiredCardAccount && this.TotalCards > 0 && this.creditCardForm.value.CreditCard == '') {
      this.alertService.infoAlert(`Por favor seleccione el nombre de la tarjeta antes de enviar el pago`);
      return;
    }

    if (this.requiredTransferAccount && this.TotalTransfer > 0 && this.transferForm.value.AccountTransf == '') {
      this.alertService.infoAlert(`Por favor seleccione una cuenta de transferencia antes de enviar el pago`);
      return;
    }

    this.isBilling = true;


    let payOk = true;
    if (this.ReceivedG < this.TotalG) {
      payOk = this.partialPayValidator();
    }
    if (payOk) {
      const Payments = this.CreatePaymentObject();

      this.blockUI.stop();

      this.OnPaymentCreated(Payments);

    }
  }

  partialPayValidator(): boolean {
    if (
      confirm(
        "el monto del pago es menor del monto total, ¿desea realizar el pago parcial?"
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  ChangeTab(event): void {
    if (event.nextId == '1') {
      this.creditCardForm.patchValue({ CreditSum: this.PreviewUnvattedAmount() });
    }
  }

  CheckVatApplied(): void {
    this.hasVatApplied = this.V_CreditCards.reduce((acc, creditCard) => { return acc + creditCard.RefundedVAT }, 0) > 0;
  }

  GetVatApplied(): number {
    return this.V_CreditCards.reduce((acc, creditCard) => { return acc + creditCard.RefundedVAT }, 0);
  }

  GetRefundeVATByCard(_vatRefunded: number): number {
    try {
      if (!_vatRefunded) return 0;

      return +_vatRefunded;
    }
    catch (error) {
      return 0;
    }
  }

  GetTerminalsByCurrency(): ITerminal[] {
    return this.terminalsByUser.filter(x => x.Currency == this.currencyPayment);
  }
}
