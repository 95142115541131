import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { debounceTime, distinctUntilChanged, filter, finalize, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

// MODELOS

// SERVICIOS
import { BusinessPartnerService, PaymentService, ParamsService, AlertService, AuthenticationService, PermsService, SapService, StorageService, ReportsService } from '../../../services/index';
import { AppConstants, ICard, IPayment, IPPTransaction, IPrinter, ITerminal, ITransaction, IVoidedTransaction } from 'src/app/models';
import { IInvoicePaymentDetail } from 'src/app/models/i-invoice-payment-detail';
import { ElectronRendererService } from 'src/app/electronrenderer.service';
import * as printJS from 'print-js';
import {IApiResponse} from "../../../models/responses";
import {IMinifiedBusinessPartner} from "../../../models/i-business-partner";

// PIPES

@Component({
  selector: 'app-cancel-payments',
  templateUrl: './cancel-payments.component.html',
  styleUrls: ['./cancel-payments.component.scss']
})
export class CancelPaymentsComponent implements OnInit {
  //varbox
  transactionsToVoid: ITransaction[];
  voidedTransactions: IVoidedTransaction[];
  currentTransactionIndex: number;
  selectedPayment: number;
  terminals: ITerminal[];
  documentKey: string;
  @BlockUI() blockUI: NgBlockUI;
  date: Date;
  nowDate: any;
  InfoInv: FormGroup;
  bpCodeNameList: string[] = []; // lista de los codigo con nombres de clientes
  invList: any[] = [];
  cards: ICard[];
  StatusFact: String;
  Formadate: string;
  invoiceNumber: number;
  totalDocument: number;
  viewParamTitles: any[] = []; // llena la lista con los titulos de las paginas parametrizados
  title: string; // titulo de la vista
  currentUser: any; // variable para almacenar el usuario actual
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  permisos: boolean = true;
  status: boolean[] = [true, false];
  invoiceSelected = false; //Existe factura seleccionada
  detailPaymentForm;
  globalStatus: any;
  paymentDetail: IInvoicePaymentDetail;



  invoiceDocEntry: number; // DocEntry de la factura

  constructor(private fbl: FormBuilder,
    private bps: BusinessPartnerService,
    private pays: PaymentService,
    private paramsService: ParamsService,
    private alertService: AlertService,
    private sPerm: PermsService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService
    , private reportsService: ReportsService
    , private electronRendererService: ElectronRendererService
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    this.date = new Date();
    this.nowDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
  }

  ngOnInit() {
    this.terminals = [];
    this.documentKey = '';
    this.fillDetailPaymentForm();
    this.checkPermits();
    this.GetParamsViewList();
    this.InfoInv = this.fbl.group({
      Cliente: ['', Validators.required],
      FechaIni: ['', Validators.required],
      FechaFin: ['', Validators.required]
    });

    this.InfoInv.controls.FechaIni.setValue(this.nowDate);
    this.InfoInv.controls.FechaFin.setValue(this.nowDate);

    this.GetMinifiedBusinessPartners();
  }

  fillDetailPaymentForm(): void {
    this.detailPaymentForm = new FormGroup({
      InvoiceNumber: new FormControl(''),
      PaymentNumber: new FormControl(''),
      Total: new FormControl(''),
      TotalFC: new FormControl(''),
      TotalTransfer: new FormControl(''),
      TotalTransferFC: new FormControl(''),
    });
  }
  // chequea que se tengan los permisos para acceder a la pagina de cancelacion de pagos
  checkPermits() {

    this.sPerm.getPerms(this.currentUser.userId).subscribe((data: any) => {
      this.blockUI.stop();
      if (data.Result) {
        let permListtable: any = data.perms;
        data.perms.forEach(Perm => {
          if (Perm.Name === "V_Cpay") {
            this.permisos = Perm.Active;
          }
        });

      } else {
        this.permisos = false;
      }
    }, error => {
      this.permisos = false;
      this.blockUI.stop();
      console.log(error);
      this.alertService.errorAlert(AppConstants.GetError(error));
    });
  }

  /**
   * This function is for get the basic info of business partners
   * @constructor
   */
  GetMinifiedBusinessPartners() {
    this.blockUI.start('Obteniendo clientes, espere por favor...');

    this.bps.GetMinifiedBusinessPartners()
      .pipe(finalize(() => this.blockUI.stop()))
      .subscribe((callback: IApiResponse<IMinifiedBusinessPartner[]>) => {
        if (callback.Result)
        {
          for (let item of callback.Data)
          {
            this.bpCodeNameList.push(`${item.CardCode} - ${item.CardName}`);
          }
        } else {
          this.alertService.errorAlert("No se encontraron socios de negocios.");
        }
      }, (error) => {
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error.error.Message}`);
      });
  }
  // llena el typehead
  searchBP = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.bpCodeNameList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  StatusFactura(status) {
    if (status == "O") {
      this.StatusFact = "Abierta";
    } else {
      this.StatusFact = "Cerrada";
    }
    return this.StatusFact;
  }
  // trae la lista de facturas a cancelar
  // no recive paramestros
  getInvList() {
    let cardCode = '';

    if (this.InfoInv.value.Cliente.split(' ')[0]) {
      cardCode = this.InfoInv.value.Cliente.split(' ')[0];
    }

    const InfoSearch = {
      CardCode: cardCode,
      FIni: this.InfoInv.value.FechaIni,
      FFin: this.InfoInv.value.FechaFin
    };

    this.blockUI.start('Procesando, espere por favor');
    this.pays.getInvList(InfoSearch).subscribe((data: any) => {
      if (data.Result) {
        this.invList.length = 0;
        this.invList = data.paymentList;
        this.blockUI.stop(); // Stop blocking
        if (data.paymentList.length === 0) {
          this.alertService.infoAlert('No se encontraron facturas en el rango de fechas seleccionadas');
        }
      } else {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorAlert('Error al obtener la lista de pago - error: ' + data.errorInfo.Message);
      }
    }, (error) => {
      this.blockUI.stop(); // Stop blocking
      this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, Error: ${error.error.Message}`);
    });
  }


  // valida que solo un check este activo
  // parametro dacentri del modelo
  CheInv(status: any, entry: any, _docNum: number, _invoiceNumber: number, InvoDocEntry: number, _documentKey: string) {
    this.blockUI.start('Procesando, espere por favor');
    this.pays.getPaymentDetail(entry).subscribe(next => {
      if (next.Result) {
        this.paymentDetail = next.InvoicePaymentDetail;
        this.invoiceNumber = _invoiceNumber;
        this.documentKey = _documentKey;
        this.invoiceDocEntry = InvoDocEntry;
        this.fillDetailPaymentForm();
        this.detailPaymentForm.patchValue({
          InvoiceNumber: _invoiceNumber,
          PaymentNumber: entry,
          Total: next.InvoicePaymentDetail.CashSum,
          TotalFC: next.InvoicePaymentDetail.CashSumFC,
          TotalTransfer: next.InvoicePaymentDetail.TrsfrSum,
          TotalTransferFC: next.InvoicePaymentDetail.TrsfrSumFC
        });
        this.totalDocument = 0;
        this.totalDocument = next.InvoicePaymentDetail.CashSum + next.InvoicePaymentDetail.CashSumFC + next.InvoicePaymentDetail.TrsfrSumFC + next.InvoicePaymentDetail.TrsfrSum;
        this.cards = next.InvoicePaymentDetail.Cards;
        this.cards.forEach(x => {
          const DATE_DUE = new Date(x.FirstDue);
          const DATE_EXPIRATION = new Date(x.CardValid);
          this.totalDocument = this.totalDocument + x.CreditSum;
          x.FirstDue = DATE_DUE;
          x.CardValid = DATE_EXPIRATION;
        });
      }
      else {
        this.alertService.errorAlert(`Error al obtener el detalle de la factura: Error: ${next.Error.Code}, Detalle: ${next.Error.Message}`);
      }
      this.blockUI.stop();
    }, error => {
      console.log(error);
      this.blockUI.stop();
    });
    this.invoiceSelected = status.target.checked;
    this.invList.forEach(inv => {
      if (inv.DocEntry === entry) {
        inv.Selected = true;
      } else {
        inv.Selected = false;

      }
    });

  }
  // envia la informacion para cancelar el pago
  // no recive paramestros
  enviarInfo() {

    if (!this.paymentDetail) {
      this.alertService.infoInfoAlert(`Por favor seleccione una factura para poderla cancelar`);
      return;
    }

    if (!this.invoiceSelected) {
      this.alertService.infoAlert('Selecccione factura a cancelar');
      return;
    }

    this.paymentDetail.DocNum = this.invoiceNumber;

    if (this.paymentDetail.Cards.length > 0) {
      this.transactionsToVoid = [];
      this.blockUI.start('Procesando, espere por favor');

      this.pays.GetCommitedPPCards(this.documentKey).pipe(finalize(() => {
        this.blockUI.stop();
      })).subscribe(next => {
        if (!next.Result) {
          this.alertService.errorAlert(`${AppConstants.GetError(next)}`);
          return;
        }

        this.transactionsToVoid = next.Data;
        this.voidedTransactions = [];
        this.currentTransactionIndex = 0;

        this.StartTransactionsVoiding();

      }, error => {
        this.alertService.errorAlert(`${AppConstants.GetError(error)}`);
        console.info('error detail', error);
      });

      return;
    }

    this.blockUI.start(`Procesnado, espere por favor`);

    this.raiseCancelPayment();
  }

  StartTransactionsVoiding(): void {


    if (this.currentTransactionIndex === this.transactionsToVoid.length) {
      this.raiseCancelPayment();
      return;
    }

    const TRANSACTION_TO_VOID = this.transactionsToVoid[this.currentTransactionIndex];

    this.CancelPPCard(TRANSACTION_TO_VOID);

    this.currentTransactionIndex++;
  }

  CancelPPCard(_transaction: ITransaction) {
    let commitedVoidedTransaction: IVoidedTransaction = null;
    let serializedTransaction = ``;
    // broken
    this.blockUI.update(`Procesando tarjeta ${this.currentTransactionIndex + 1} de ${this.transactionsToVoid.length}`);
    this.blockUI.start(`Procesando tarjeta ${this.currentTransactionIndex + 1} de ${this.transactionsToVoid.length}`);
    this.pays.CancelCommitedTransaction(_transaction).pipe(finalize(() => {
      !commitedVoidedTransaction || this.commitVoidedTransaction(commitedVoidedTransaction, serializedTransaction)
    })).subscribe(next => {
      if (next.Result) {
        const EMVS_STREAM = JSON.parse(next.Data)['EMVStreamResponse'];

        commitedVoidedTransaction = {
          InvoiceNumber: _transaction.InvoiceNumber,
          SerializedTransaction: next.Data,
          TerminalId: _transaction.TerminalId,
          TransactionId: EMVS_STREAM.transactionId
        } as IVoidedTransaction;

        serializedTransaction = next.Data;
      }
      else {
        this.blockUI.stop();
        this.blockUI.reset();
        this.alertService.errorAlert(`${AppConstants.GetError(next)}`);
      }
    }, error => {
      this.blockUI.stop();
      this.blockUI.reset();


      this.blockUI.reset();
      let message = AppConstants.GetError(error);

      if (message && message.includes(`Unknown Error`)) {
        message = `Parece que el servicio pin pad no se está ejecutando. ` + message;
      }
      this.alertService.errorAlert(`Error: ` + message);
    });
  }

  commitVoidedTransaction(_voidedTransaction: IVoidedTransaction, _serializedTransaction: string): void {

    if (!_voidedTransaction) {
      this.StartTransactionsVoiding();
      return; // Its no required to notify to the user an uncommited transaction
    }

    this.blockUI.start(`Procesando en db, espere por favor`);
    this.pays.CommitCanceledCard(_voidedTransaction).pipe(
      finalize(() => {
        this.StartTransactionsVoiding();

      })
    ).subscribe({
      next: (callback) => {
        if (callback.Result) {
          this.alertService.successInfoAlert(`Transacción completada`, 2000);

          this.voidedTransactions.push({
            InvoiceNumber: _voidedTransaction.InvoiceNumber,
            SerializedTransaction: callback.Data.SerializedTransaction,
            TerminalId: _voidedTransaction.TerminalId,
            TransactionId: _voidedTransaction.TransactionId,
            CreationDate: callback.Data.CreationDate
          } as IVoidedTransaction);
        }
        else {
          this.alertService.infoAlert(`Transacción anulada pero no se pudo respaldar en base de datos. ${AppConstants.GetError(callback)}`);
        }
      },
      error: error => {
        this.blockUI.stop();
        console.info(error);
        this.alertService.errorAlert(AppConstants.GetError(error));
      }
    });
  }

  PrintVoid(): void {

    if (!this.voidedTransactions || this.voidedTransactions.length <= 0) return;

    let rawData = `>ct:${this.voidedTransactions.length}>ct_end`;
    let appliedPinpadInvoices = ``;

    this.terminals

    this.voidedTransactions.forEach((x, index) => {

      // make some mappings
      const EMVSTREAM = JSON.parse(x.SerializedTransaction)['EMVStreamResponse'];
      const INDEX = index + 1;
      const AMOUNT = +EMVSTREAM.salesAmount.slice(0, EMVSTREAM.salesAmount.length - 2) + "." + EMVSTREAM.salesAmount.slice(-2);
      const TERMINAL: ITerminal = this.terminals.find(y => y.TerminalId == x.TerminalId) || { Currency: ' ' } as ITerminal;

      rawData += `>tr${INDEX}:${x.TerminalId}>tr_end${INDEX}>am${INDEX}:${AMOUNT}>am_end${INDEX}>ti${INDEX}:${x.InvoiceNumber}`;
      rawData += `>ti_end${INDEX}>st${INDEX}:${EMVSTREAM['systemTraceNumber']}>st_end${INDEX}>rn${INDEX}:${EMVSTREAM['referenceNumber']}`;
      rawData += `>rn_end${INDEX}>na${INDEX}:${EMVSTREAM['authorizationNumber']}>na_end${INDEX}>cu${INDEX}:${TERMINAL.Currency}>cu_end${INDEX}>fc${INDEX}:${x.CreationDate.toString()}>fc_end${INDEX}`;
      appliedPinpadInvoices += `${x.InvoiceNumber},`;
    });

    appliedPinpadInvoices.slice(0, -1);

    this.reportsService.PrintVoucher(this.selectedPayment, rawData).pipe(finalize(() => this.blockUI.stop())).subscribe(data => {
      try {
        if (data.Result) {
          if (this.electronRendererService.CheckElectron()) {
            let fileName = 'Invoice_' + appliedPinpadInvoices + '.pdf';
            const PRINTERCONFIGURATION = JSON.parse(this.storageService.getCompanyConfiguration().PrinterConfiguration) as IPrinter;
            let file = {
              "fileName": fileName,
              "file": data.Data,
              "defaultPrinter": PRINTERCONFIGURATION.DisplayName
            };
            this.electronRendererService.send('Print', file);
          }
          else {
            printJS({
              printable: data.Data,
              type: 'pdf',
              base64: true
            });
          }
        } else {
          this.alertService.errorInfoAlert(`Error obteniendo reporte, error: ${data.Error.Code}-${data.Error.Message}`);
        }

      } catch (error) {
        console.info(error);
      }

    }, error => {
      this.alertService.errorInfoAlert(`${AppConstants.GetError(error)}`);
    });
  }

  raiseCancelPayment(_ppTransaction: IPPTransaction = null): void {
    var entry = '';
    var numFact = '';
    let docNumPago = -1;
    this.invList.forEach(inv => {

      this.selectedPayment = inv.DocEntry;

      if (inv.Selected) {
        entry = inv.DocEntry;
        numFact = inv.DocNum;
        docNumPago = inv.DocNumPago;
      }
    });
    if (!this.invoiceSelected) {
      this.alertService.infoAlert('Selecccione factura a cancelar');
      return;
    }
    const canPay = {
      DocEntry: entry
    };

    this.blockUI.update(`Procesando pago, espere por favor`);

    this.pays.CancelPayment(canPay).pipe(finalize(() => this.blockUI.stop())).subscribe((data: any) => {

      if (data.Result) {
        this.blockUI.reset();
        this.alertService.ContinueAlert(`Pago anulado`, `Número de Pago: ${docNumPago}<br/>  Número de Factura: ${numFact}`);
        this.invList.length = 0;
        this.PrintVoid();
      } else {
        this.alertService.errorAlert('Error: ' + entry + ' Error: ' + data.Error.Message);
      }
    }, (error) => {
      this.alertService.errorInfoAlert(`Error: ${AppConstants.GetError(error)}`);
    });
  }

  // llena los campos de la tabla de items con los campos parametriados
  GetParamsViewList() {
    this.paramsService.getParasmView()
      .subscribe((data: any) => {
        this.blockUI.stop();
        if (data.Result) {
          this.viewParamTitles = data.Params.filter(param => {
            return param.type === 6;
          });
          this.ChargeParamstoView();
        } else {
          this.alertService.errorAlert('Error al cargar los parámetros de la página - ' + data.errorInfo.Message);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`Error al intentar conectar con el servidor, error: ${error}`);
      });
  }

  raiseModelDetail(_docNum: number, _invoiceNumber: number, _payMent: IPayment, status: any): void {
    this.invList.forEach(inv => inv.Selected = false);
    this.globalStatus = status;
    if ((<HTMLInputElement>document.getElementById('' + this.invoiceNumber)) !== null) (<HTMLInputElement>document.getElementById('' + this.invoiceNumber)).checked = false;
    this.blockUI.start('Procesando, espere por favor');
    this.pays.getPaymentDetail(_payMent.DocEntry).subscribe(next => {
      if (next.Result) {
        this.paymentDetail = next.InvoicePaymentDetail;
        this.invoiceNumber = _invoiceNumber;
        this.fillDetailPaymentForm();
        this.detailPaymentForm.patchValue({
          InvoiceNumber: _invoiceNumber,
          PaymentNumber: _docNum,
          Total: next.InvoicePaymentDetail.CashSum,
          TotalFC: next.InvoicePaymentDetail.CashSumFC,
          TotalTransfer: next.InvoicePaymentDetail.TrsfrSum,
          TotalTransferFC: next.InvoicePaymentDetail.TrsfrSumFC
        }
        );
        this.totalDocument = 0;
        this.totalDocument = next.InvoicePaymentDetail.CashSum + next.InvoicePaymentDetail.CashSumFC + next.InvoicePaymentDetail.TrsfrSumFC + next.InvoicePaymentDetail.TrsfrSum;
        this.cards = next.InvoicePaymentDetail.Cards;
        this.cards.forEach(x => {
          const DATE_DUE = new Date(x.FirstDue)
          const DATE_EXPIRATION = new Date(x.CardValid);
          this.totalDocument = this.totalDocument + x.CreditSum;
          x.FirstDue = DATE_DUE;
          x.CardValid = DATE_EXPIRATION;
        });
        (<HTMLButtonElement>document.getElementById('triggerDetailModal')).click();
      }
      else {
        this.alertService.errorAlert(`Error al obtener el detalle de la factura: Error: ${next.Error.Code}, Detalle: ${next.Error.Message}`);
      }
      this.blockUI.stop();
    }, error => {
      console.log(error);
      this.blockUI.stop();
    }, () => { this.blockUI.stop() });
    this.invoiceSelected = status.target.checked;
  }

  setSelectedInvoice(): void {
    this.invList.forEach(x => {
      if (x.DocNum === this.invoiceNumber) {
        if ((<HTMLInputElement>document.getElementById('' + x.DocNumPago)) !== null) {
          this.invList.forEach(inv => inv.Selected = inv.DocNum === this.invoiceNumber);
          this.invoiceSelected = true;
          (<HTMLInputElement>document.getElementById('' + x.DocNumPago)).checked = true;
        }
      }
    });
  }

  // Carga los datos parametrizados en las variables
  ChargeParamstoView() {
    // parametrizacion del titulo
    let obj = this.viewParamTitles.filter(param => {
      return param.Name === 'T_cancelPay';
    });
    this.title = obj[0].Text;
  }
}
