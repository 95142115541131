import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import { AppConstants, IBusinessPartner, IUdf, IUdfTarget } from './../models/index';

// SERVICIOS
import { StorageService } from './storage.service';
import { IPadronInfo } from '../models/i-padron-info';
import { Observable, throwError } from 'rxjs';
import { IResponse } from '../models/i-api-response';
import {IBPCardCodes, IMinifiedBusinessPartner} from '../models/i-business-partner';
import { IIdentificationType } from '../models/i-identification-type';
import { catchError, timeout } from 'rxjs/operators';
import {IApiResponse} from "../models/responses";
import {BusinessPartnerModel} from "../models/i-business-partner-model";

// PIPES
@Injectable({
  providedIn: 'root'
})
export class BusinessPartnerService {

  constructor( private http: HttpClient,
               private storage: StorageService ) {
  }

  /**
   * Get the basic info of business partners
   * @constructor
   */
  GetMinifiedBusinessPartners(): Observable<IApiResponse<IMinifiedBusinessPartner[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());

    const url = `api/BusinessPartners/GetMinifiedBusinessPartners`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IApiResponse<IMinifiedBusinessPartner[]>>(url, { headers });
  }

  /**
   * Get the business partner details
   * @param cardCode The card code of business partner to get
   * @constructor
   */
  GetBusinessPartner(_code: string, _cardCode: string): Observable<IApiResponse<BusinessPartnerModel>> {
    const token = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<IApiResponse<BusinessPartnerModel>>('api/BusinessPartners/GetBusinessPartner',
    {
      headers,
      params: {
        Code: _code,
        CardCode: _cardCode
      }
    });
  }

  // funcion para obtener los datos de fe de un cliente cuando es un cliente de contado
  // no recibe parametros
  GetCustomersCont(idType: string, idNumber: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetBusinessPartnerFEInfo?idType=${idType}&idNumber=${idNumber}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // funcion para obtener los datos de fe de un cliente cuando es un cliente de credito
  // no recibe parametros
  GetCustomersCred(cardCode: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetBusinessPartnerFEInfo?cardCode=${cardCode}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // funcion para obtener los datos de fe de un cliente cuando es un cliente de contado desde el padron
  // no recibe parametros
  GetCustomersContPadron(idNumber: string) {
    const url = `${this.storage.GetUrlPadron()}${idNumber}`;

    return this.http.get<IPadronInfo>(url, {}).pipe(

      timeout(this.storage.GetTimeOutPadron()),

      catchError(error => {

        if(error.name === 'TimeoutError'){

          return throwError(`Tiempo de espera agotado`);

        } else {

          return throwError(error);

        }

      })

    );
  }

  GetSuppliers() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetSuppliers`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<any>(url, { headers });
  }

  // función para obtener un Cliente
  GetCustomerById(customerId: string) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetCustomerbyCode?CardCode=${customerId}`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  // actualizar Socio de Negocio
  UpdateCustomer(businesspartnerForm: FormGroup, U_PROVINCIA: string, U_CANTON: string, U_DISTRITO: string, U_BARRIO: string, _udfs: IUdfTarget[]) {
    const businesspartner: IBusinessPartner = {
      CardCode: businesspartnerForm.value.CardCode,
      CardName: businesspartnerForm.value.CardName,
      CardType: businesspartnerForm.value.CardType,
      Phone1: businesspartnerForm.value.Phone1,
      LicTradNum: businesspartnerForm.value.LicTradNum,
      E_Mail: businesspartnerForm.value.E_Mail,
      U_TipoIdentificacion: businesspartnerForm.value.U_TipoIdentificacion,
      // U_provincia: businesspartnerForm.value.U_provincia,
      // U_canton: businesspartnerForm.value.U_canton,
      // U_distrito: businesspartnerForm.value.U_distrito,
      // U_barrio: businesspartnerForm.value.U_barrio,
      U_provincia: U_PROVINCIA,
      U_canton: U_CANTON,
      U_distrito: U_DISTRITO,
      U_barrio: U_BARRIO,
      U_direccion: businesspartnerForm.value.U_direccion,
      UdfTarget: _udfs
    }

    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/UpdateCustomer`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url,businesspartner,{ headers });

  }

  // funcion para crear Clientes
  CreateCustomer(businesspartnerForm: FormGroup, U_PROVINCIA: string, U_CANTON: string, U_DISTRITO: string, U_BARRIO: string, _udfs: IUdfTarget[]){
    const businesspartner: IBusinessPartner = {
      CardType: businesspartnerForm.value.CardType,
      CardCode: businesspartnerForm.value.CardCode,
      CardName: businesspartnerForm.value.CardName,
      Phone1: businesspartnerForm.value.Phone1,
      LicTradNum: businesspartnerForm.value.LicTradNum,
      E_Mail: businesspartnerForm.value.E_Mail,
      U_TipoIdentificacion: businesspartnerForm.value.U_TipoIdentificacion,
      // U_provincia: businesspartnerForm.value.U_provincia,
      // U_canton: businesspartnerForm.value.U_canton,
      // U_distrito: businesspartnerForm.value.U_distrito,
      // U_barrio: businesspartnerForm.value.U_barrio,
      U_provincia: U_PROVINCIA,
      U_canton: U_CANTON,
      U_distrito: U_DISTRITO,
      U_barrio: U_BARRIO,
      U_direccion: businesspartnerForm.value.U_direccion,
      UdfTarget: _udfs
    }
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/CreateCustomer`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<any>(url,businesspartner, { headers });

  }

  // funcion para obtener clientes y proveedores
  // no recibe parametros
  GetAllBusinessPartner() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetCustomer`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(url, { headers });
  }

  GetCustomerCardCodes(): Observable<IResponse<IBPCardCodes[]>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetCustomerCardCodes`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<IBPCardCodes[]>>(url, {headers});
  }

  GetDefaultPatientCardCode(): Observable<IResponse<string>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetDefaultPatientCardCode`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<IResponse<string>>(url, {headers});
  }

  GetIdentificationTypes(): Observable<IResponse<IIdentificationType[]>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/BusinessPartners/GetIdentificationTypes`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    return this.http.get<IResponse<IIdentificationType[]>>(url, {headers});
  }

}
