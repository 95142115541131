import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IUIEvent } from '../models';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private uiEvent: Subject<IUIEvent>;
  constructor() { 
    this.uiEvent = new Subject();
  }

  PublishEvent(_event: IUIEvent): void {
    try {
      if (!_event) {
        throw Error(`EL EVENTO LLEGO SIN INSTANCIA`);
      }
      else {
        if (!_event.Target) {
          throw Error(`NO HA ESPECIFICADO LA FUNCION A EJECUTAR EN LA VISTA DESTINO`);
        }

        if (!_event.View) {
          throw Error(`NO HA ESPECIFICADO LA VISTA DESTINO`);
        }
      }
      this.uiEvent.next(_event);
    }
    catch(error) {
      throw Error(error);
    }
  }

  Flow(): Subject<IUIEvent> {
    try {
      return this.uiEvent;
    }
    catch(error) {
      throw Error(error);
    }
  }
}
