import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, ICommitedTransaction, ITerminal, ITerminalByUser } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { IBaseResponse, ICommitedTransactionsResponse, ITerminalResponse, ITerminalsByUserResponse, ITerminalsResponse } from '../models/responses';
import { Observable } from 'rxjs';
import { IPPBalanceRequest } from '../models/i-ppbalance-request';
import { IResponse } from '../models/i-api-response';
import { IACQTransaction, ICLTerminal, IPPCashDeskClosing, PPBalance } from '../models/i-pp-transaction';

// PIPES

@Injectable({
  providedIn: 'root'
})
export class BankService {

  constructor( private http: HttpClient,
    private storage: StorageService ) {
  }

  // funcion para obtener los bancos
  // no recibe parametros
  getAccountsBank() {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(`api/Banks/GetAccountsBank`,
      { headers });
  }

  PreBalance(_clTerminal: ICLTerminal): Observable<IResponse<string>> {
    const ppURL = this.storage.GetUrlPinpad();
    return this.http.post<IResponse<string>>(`${ppURL}api/Banks/PreBalance`, _clTerminal);
  }

  PPCashDeskClosing(_cashDeskClosing: IPPCashDeskClosing ): Observable<IResponse<ICommitedTransaction[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<ICommitedTransaction[]>>(`api/Banks/SavePreBalance`, _cashDeskClosing, { headers });
  }

  GetRequestsFromRegisters(_balanceRequest: IPPBalanceRequest): Observable<IResponse<ICommitedTransaction[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post<IResponse<ICommitedTransaction[]>>(`api/Banks/GetCashDeskClosings`, _balanceRequest, { headers });
  }

  Balance(_clTerminal: ICLTerminal): Observable<IResponse<string>> {
    const ppURL = this.storage.GetUrlPinpad()
    return this.http.post<IResponse<string>>(`${ppURL}api/Banks/Balance`, _clTerminal);
  }

  GetTransactionsPinpadTotal(_terminalId: string) : Observable<IResponse<string>>{
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get<IResponse<string>>(`api/Banks/GetTransactionsPinpadTotal`, {
      headers,
      params: {
        terminalId: _terminalId
      }
    });
  }
}
