import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {IApiResponse, IBaseResponse, ITerminalResponse, ITerminalsByUserResponse} from "../models/responses";
import {ITerminal, ITerminalByUser} from "../models";

@Injectable({
  providedIn: 'root'
})
export class TerminalsService {

  private readonly CONTROLLER = 'api/Terminals'
  constructor(private http: HttpClient) { }

  /**
   * Send a request to get the all or only active terminals
   * @param _onlyActiveTerminals Indicates if should request all or only active terminals
   * @constructor
   */
  GetTerminals(_onlyActiveTerminals: boolean = true): Observable<IApiResponse<ITerminal[]>>
  {
    return this.http.get<IApiResponse<ITerminal[]>>(`${this.CONTROLLER}/GetTerminals`, {
      params: {
        onlyActiveTerminals: `${_onlyActiveTerminals}`
      }
    })
  }

  /**
   * Send a request to get an specified terminal
   * @param _id The id of the terminal to request
   * @constructor
   */
  GetTerminal(_id: number): Observable<ITerminalResponse>
  {
    return this.http.get<ITerminalResponse>(`${this.CONTROLLER}/GetTerminal`, {
      params: {
        id: `${_id}`
      }
    });
  }

  /**
   * Send a request to create a terminal
   * @param _terminal The terminals that will be created
   * @constructor
   */
  CreateTerminal(_terminal: ITerminal): Observable<IBaseResponse>
  {
    return this.http.post<IBaseResponse>(`${this.CONTROLLER}/CreateTerminal`, _terminal);
  }

  /**
   * Send a request to update a terminal
   * @param _terminal The terminal that will be updated
   * @constructor
   */
  UpdateTerminal(_terminal: ITerminal): Observable<IBaseResponse> {
    return this.http.post<IBaseResponse>(`${this.CONTROLLER}/UpdateTerminal`, _terminal);
  }

  /**
   * Send a request to update the terminals assigned to the specified user
   * @param _terminalsByUser The new terminals that will be assigned to the specified user
   * @param _userId The user id that terminals will be updated
   * @constructor
   */
  UpdateTerminalsByUser(_terminalsByUser: ITerminalByUser[], _userId: string): Observable<IBaseResponse> {
    return this.http.post<IBaseResponse>(`${this.CONTROLLER}/UpdateTerminalsByUser`,
      {
        'UserId': _userId,
        'TerminalsByUser': _terminalsByUser
      });
  }

  /**
   * Send a request to get the specified user terminals
   * @param _userId The user id for request the assigned terminals
   * @constructor
   */
  GetTerminalsByUser(_userId: string): Observable<IApiResponse<ITerminalByUser[]>>
  {
    return this.http.get<IApiResponse<ITerminalByUser[]>>(`${this.CONTROLLER}/GetTerminalsByUser`,
      {
        params: {
          userId: encodeURIComponent(_userId)
        }
      });
  }
}
