import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import { AppConstants, IPPTransaction, IUdfTarget, ITerminal, ITransaction, IVoidedTransaction } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { IBaseResponse, IPPTransactionResponse, IInvoicePaymentDetailResponse } from '../models/responses';
import { CurrencyPP } from '../models/constantes';
import { IResponse } from '../models/i-api-response';
import { StoredTransaction } from '../models/i-pp-transaction';

// PIPES

@Injectable({
  providedIn: 'root'
})
export class PaymentService {


  constructor(private http: HttpClient,
    private storage: StorageService) {
  }

  // funcion para obtener los parametros de la vista de facturacion
  // no recibe parametros
  getPayInvoices(searchForm: FormGroup) {

    const code = searchForm.value.customer.split(' - ')[0];

    const view = 1;
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(`api/Payment/GetPayInvoices?CardCode=${code}&Sede=${searchForm.value.sede}&Currency=${searchForm.value.currency}`,
      { headers });
  }
  // funcion para obtener los parametros de la vista de facturacion
  // no recibe parametros
  getPayApInvoices(searchForm: FormGroup) {
    const code = searchForm.value.supplier.split(' - ')[0];
    const view = 1;
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.get(`api/Payment/GetPayApInvoices?CardCode=${code}&Sede=${searchForm.value.sede}&Currency=${searchForm.value.currency}`,
      { headers });
  }
  // funcion para crear un pago
  // parametros el modelo de pago
  createPayInvoices(Payments: any, pagocuenta: boolean, _udfs: IUdfTarget[]) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `api/Documents/CreatePaymentRecived`;

    return this.http.post(url,
      {
        ...Payments,
        'isPayAccount': pagocuenta,
        'UdfTarget': _udfs

      },
      { headers }
    );
  }

  // funcion para crear un pago
  // parametros el modelo de pago
  createPayApInvoices(Payments: any, _udfs: IUdfTarget[]) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    const url = `api/Documents/CreatePayApInvoices`;
    return this.http.post(url,
      {
        ...Payments,
        'UdfTarget': _udfs
      },
      { headers });
  }
  // obtiene la lista de facturas
  // parametros modelo con al informacion que se necesita para buscar los documentos
  getInvList(InfoSearch: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const url = `api/Payment/GetPaymentList`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    return this.http.post(url,
      InfoSearch,
      { headers });
  }
  // envia el pago a anular
  // parametros modelo de pago con la informacion de la factura a cancelar
  CancelPayment(Payments: any) {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    const url = `api/Payment/CancelPayment`;

    return this.http.post(url,
      Payments,
      { headers }
    );
  }

  RequestPinPadPayment(_amount: number, _uniqueInvCode: string, _currencyPayment: string, _bacId: string, _terminalId: string): Observable<IResponse<string>> {

    const ppURL = this.storage.GetUrlPinpad();

    const url = `${ppURL}api/Payment/RequestPinPadPayment`;

    let PAYMENT_OBJECT = {
      'Amount': _amount,
      'DocumentKey': _uniqueInvCode,
      'InvoiceNumber': _bacId,
      'Currency': _currencyPayment == 'COL' ? CurrencyPP.LOCAL_CURRENCY : CurrencyPP.USD,
      'TerminalId': _terminalId,
    };

    return this.http.post<IResponse<string>>(url, PAYMENT_OBJECT);
  }

  updatePinPadPayment(_pinPadCards: IPPTransaction[]): Observable<IPPTransactionResponse> {
    // const token = JSON.parse(this.storage.getCurrentSessionOffline());
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${token.access_token}`
    // });

    const ppURL = this.storage.GetUrlPinpad();

    const url = `${ppURL}api/Payment/UpdatePinPadPayment`;

    return this.http.post<IPPTransactionResponse>(url,
      _pinPadCards,
      {}
    );
  }

  GetCommitedPPCards(_documentKey: string): Observable<IResponse<ITransaction[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `api/Payment/GetPPTransactionByDocumentKey?_documentKey=${_documentKey}`;

    return this.http.get<IResponse<ITransaction[]>>(url, { headers });
  }
/**
 *  This function is used only when a document requires to be printed again
 * @param _documentKey Unique key asociated to documents
 * @returns A list of transactions per invoice
 */
  GetCommitedTransactions(_documentKey: string): Observable<IResponse<ITransaction[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `api/Payment/GetCommitedTransactions?_documentKey=${_documentKey}`;

    return this.http.get<IResponse<ITransaction[]>>(url, { headers });
  }

  PushTransaction(_storedTransaction: StoredTransaction): Observable<IResponse<StoredTransaction>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });
    const url = `api/Payment/CreateStoredTransaction`;

    return this.http.post<IResponse<StoredTransaction>>(url, _storedTransaction, { headers });
  }

  PushTransactions(_storedTransactions: StoredTransaction[]): Observable<IResponse<StoredTransaction>[]> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const HEADERS = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    const URL = `api/Payment/CreateStoredTransactions`;

    return this.http.post<IResponse<StoredTransaction>[]>(URL, _storedTransactions, { headers: HEADERS });
  }

  CheckTransactionBalanceStatus(_docEntry: number): Observable<IResponse<IPPTransaction[]>> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': `Bearer ${token.access_token}`
    // });

    const ppURL = this.storage.GetUrlPinpad();
    const url = `${ppURL}api/Payment/GetTransactionsByDocEntry?_docEntry=${_docEntry}`;

    return this.http.get<IResponse<IPPTransaction[]>>(url, {});
  }

  // cancelPinPadCards(_paymentDetail: IInvoicePaymentDetail): Observable<BaseResponse> {
  //   // const token = JSON.parse(this.storage.getCurrentSessionOffline());
  //   // const headers = new HttpHeaders({
  //   //   'Content-Type': 'application/json',
  //   //   'Authorization': `Bearer ${token.access_token}`
  //   // });
  //   const ppURL = this.storage.GetUrlPinpad();

  //   const url = `${ppURL}api/Payment/CancelPinPadCards`;

  //   return this.http.post<BaseResponse>(url, _paymentDetail, { }
  //   );
  // }

  CancelCommitedTransaction(_transaction: ITransaction): Observable<IResponse<string>> {
    const ppURL = this.storage.GetUrlPinpad();

    const url = `${ppURL}api/Payment/CancelCard`;

    return this.http.post<IResponse<string>>(url, _transaction);
  }

  CommitCanceledCard(_voidedTransaction: IVoidedTransaction): Observable<IResponse<IVoidedTransaction>> {

    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `api/Payment/CommitCanceledCard`;

    return this.http.post<IResponse<IVoidedTransaction>>(url, _voidedTransaction, { headers });
  }

  getPaymentDetail(_docNum: number): Observable<IInvoicePaymentDetailResponse> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `api/Payment/GetInvoicePaymentDetail?_docEntry=${_docNum}`;

    return this.http.get<IInvoicePaymentDetailResponse>(url, { headers });
  }

  GetPPTransactionByInvoiceNumber(_invoiceNumber: string): Observable<IResponse<IPPTransaction>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `api/Payment/GetPPTransactionByInvoiceNumber?_documentKey=${_invoiceNumber}`;

    return this.http.get<IResponse<IPPTransaction>>(url, { headers });
  }

  GetTransactionDetails(_documentKey: string): Observable<IResponse<ITransaction[]>> {
    const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token.access_token}`
    });

    const url = `api/Payment/GetTransactionDetails?_documentKey=${_documentKey}`;

    return this.http.get<IResponse<ITransaction[]>>(url, { headers });
  }
}
